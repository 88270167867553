<template>
  <v-container fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('banking_write')">
      <v-col lg="6" offset-lg="3">
        <FormHeader v-bind="editSpeedPointHeader" />
        <v-card>
          <!--New Machine form -->
          <v-form
            @submit.prevent="updateBankAccount"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="bank_account_name"
                    label="Name"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="nameRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="bank_account_status"
                    label="Status"
                    :items="['Active', 'Inactive']"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="statusRules"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!--Opening Balance-->
              <v-row class="mt-3">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model.number="bank_account_opening_balance"
                    type="number"
                    label="Opening Balance"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/bank-accounts" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="confirmDeleteBankAccount"
              color="#ce2458"
              text
              x-large
              :loading="loading_delete"
            >
              Delete
              <v-icon right>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              @click="updateBankAccount"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading_update"
            >
              Update
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
    <!--Delete Alert Modal-->
    <BankAccountsDeleteDisableDialog :delete_alert_modal="delete_alert_modal" />
    <!--Delete Confirmation Modal-->
    <BankAccountDeleteConfirmDialog
      :delete_bank_account_modal="delete_bank_account_modal"
      :loading_delete="loading_delete"
      @deleteAccount="deleteBankAccount($event)"
      @closeDialog="closeDialog($event)"
    />
  </v-container>
</template>

<script>
//Connect to database
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import FormHeader from "../../../components/Forms/FormHeader";
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { mixin_speed_point_validation_rules } from "../mixins/mixin_speed_point_validation_rules";
import store from "../store/store";
import { mapState } from "vuex";
import { showSnackbar } from "../../../globalActions/index";
import {
  newDateToISO,
  firebaseTimestamp,
  firestoreDocProps,
} from "../../../composables/external";
import {
  updateBankAccount,
  deleteBankAccount,
  testTransactionLength,
  confirmDeleteBankAccount,
} from "../data/external_bank_accounts";

export default {
  name: "NewSpeedPoint",
  mixins: [mixin_UserRoles, mixin_speed_point_validation_rules],
  components: {
    FormHeader,
    NoPermissionsCard,
    BankAccountsDeleteDisableDialog: () =>
      import("../components/BankAccountsDeleteDisableDialog"),
    BankAccountDeleteConfirmDialog: () =>
      import("../components/BankAccountDeleteConfirmDialog"),
  },

  data() {
    return {
      //Initial form values
      bank_account_id: null,
      account_id: null,
      bank_account_name: null,
      bank_account_status: null,
      bank_account_opening_balance: null,
      loading_update: false,
      loading_delete: false,
      transactions: [],
      delete_alert_modal: false,
      delete_bank_account_modal: false,
      bank_interface_type: "speed_points",
      propArray: [
        "bank_account_name",
        "bank_account_status",
        "bank_account_opening_balance",
        "account_id",
      ],
    };
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    editSpeedPointHeader: (state) => state.editSpeedPointHeader,
  }),
  created() {
    //  Test if there are any transactions related to this account
    this.testTransactionLength();
    //Captures user that created the sales order
    this.speed_point_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
      .where("uid", "==", this.speed_point_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.speed_point_created_by_name = doc.data().displayName;
        });
      });
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("speed_points")
      .doc(to.params.bank_account_id)
      .get()
      .then((doc) => {
        //vm assigns all the values we want to put in the template
        next((vm) => {
          vm.bank_account_id = doc.data().bank_account_id;
          vm.bank_account_name = doc.data().bank_account_name;
          vm.bank_account_status = doc.data().bank_account_status;
          vm.bank_account_opening_balance =
            doc.data().bank_account_opening_balance;
          vm.account_id = doc.data().account_id;
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    newDateToISO,
    firebaseTimestamp,
    updateBankAccount,
    testTransactionLength,
    confirmDeleteBankAccount,
    deleteBankAccount,
    fetchData() {
      db.collection("speed_points")
        .doc(this.$route.params.bank_account_id)
        .get()
        .then((doc) => {
          firestoreDocProps(this.propArray, doc.data());
        });
    },
    //Reroute to bank account page after database has been updated
    routeToTable(message) {
      showSnackbar(`Speed Point ${message} successfully`);
      this.$router.push("/bank-accounts");
    },
    validate() {
      this.$refs.form.validate();
    },
    closeDialog(delete_bank_account_modal) {
      this.delete_bank_account_modal = delete_bank_account_modal;
    },
  },
};
</script>
