import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    
} from '../data/data'

Vue.use(Vuex);

import {
    //BUTTONS
    primaryCTA, secondaryCTA,

    //DATA TABLE HEADERS
    bankAccountsHeaders, creditCardHeaders, pettyCashHeaders,
    bankStatementHeaders, speedPointHeaders,

    //FORM HEADERS
    newBankAccountHeader, editBankAccountHeader, newSpeedPointHeader,
    editSpeedPointHeader,

} from '../data/data'

export default new Vuex.Store({
    state: {
    //BUTTONS
    primaryCTA, secondaryCTA,
    

    //DATA TABLE HEADERS
    bankAccountsHeaders, creditCardHeaders, pettyCashHeaders,
    bankStatementHeaders, speedPointHeaders,
    

    //FORM HEADERS
    newBankAccountHeader, editBankAccountHeader, newSpeedPointHeader,
    editSpeedPointHeader,
    
    },
    mutations: {},
    actions: {},
    modules: {},
});