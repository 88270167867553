<template>
  <v-container fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('banking_write')">
      <v-col lg="6" offset-lg="3">
        <FormHeader v-bind="newSpeedPointHeader" />
        <v-card>
          <!--New Machine form -->
          <v-form
            @submit.prevent="saveSpeedPoint"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="bank_account_name"
                    label="Name"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="nameRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="bank_account_status"
                    label="Status"
                    :items="['Active', 'Inactive']"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="statusRules"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!--Opening Balance-->
              <v-row class="mt-3">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model.number="bank_account_opening_balance"
                    type="number"
                    label="Opening Balance"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/bank-accounts" color="#ce2458" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="saveSpeedPoint"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Submit
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
  </v-container>
</template>

<script>
//Connect to database
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import FormHeader from "../../../components/Forms/FormHeader";
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { mixin_speed_point_validation_rules } from "../mixins/mixin_speed_point_validation_rules";
import store from "../store/store";
import { mapState } from "vuex";
import { showSnackbar } from "../../../globalActions/index";
import {
  firestoreDocProps,
  newDateToISO,
  firebaseTimestamp,
} from "../../../composables/external";

export default {
  name: "NewSpeedPoint",
  mixins: [mixin_UserRoles, mixin_speed_point_validation_rules],
  components: { FormHeader, NoPermissionsCard },

  data() {
    return {
      //Initial form values
      bank_account_id: null,
      account_id: null,
      bank_account_name: null,
      bank_account_status: "Active",
      bank_account_opening_balance: 0,
      bank_account_type: null,
      speed_point_created_by_id: null,
      speed_point_created_by_name: null,
      speed_point_date_added: null,
      speed_point_timestamp: null,
      loading: false,
    };
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    newSpeedPointHeader: (state) => state.newSpeedPointHeader,
  }),
  created() {
    //Captures user that created the sales order
    this.speed_point_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
      .where("uid", "==", this.speed_point_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.speed_point_created_by_name = doc.data().displayName;
        });
      });
  },
  methods: {
    firestoreDocProps,
    newDateToISO,
    firebaseTimestamp,
    //Reroute to bank account page after database has been updated
    routeToTable(message) {
      showSnackbar(`Speed Point ${message} successfully`);
      this.$router.push("/bank-accounts");
    },
    validate() {
      this.$refs.form.validate();
    },
    saveSpeedPoint() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const batch = db.batch();

        const bankDocRef = db.collection("speed_points").doc();
        const accountDocRef = db.collection("chart_of_accounts").doc();
        const cashDocRef = db.doc("chart_of_accounts/UWrVnQN4i3rE0umfoyOR");

        const propArray = [
          "bank_account_name",
          "bank_account_status",
          "bank_account_opening_balance",
          "speed_point_created_by_id",
          "speed_point_created_by_name",
        ];
        //  1: Add bank account
        batch.set(bankDocRef, {
          ...firestoreDocProps(propArray, this),
          bank_account_id: bankDocRef.id,
          bank_account_type: "Speed Point",
          speed_point_date_added: this.newDateToISO(),
          speed_point_timestamp: this.firebaseTimestamp(),
          account_id: accountDocRef.id,
        });
        //  2: Add to chart of accounts
        batch.set(accountDocRef, {
          account_id: accountDocRef.id,
          account_name: this.bank_account_name,
          account_type: "Assets",
          account_sub_type: "Current Asset",
          financial_statement: "Balance Sheet",
          account_status: "Active",
          parent_account: {
            account_code: "6030",
            account_id: "UWrVnQN4i3rE0umfoyOR",
            account_name: "Cash",
          },
          bank_account_id: bankDocRef.id,
          locked: true,
          is_contra: false,
          account_created_by_id: this.speed_point_created_by_id,
          account_created_by_name: this.speed_point_created_by_name,
          account_created_timestamp: this.newDateToISO(),
          account_date_created: this.firebaseTimestamp(),
        });
        // 3: Add account ids to sub-ledger array on "Cash" account
        batch.update(cashDocRef, {
          sub_ledgers: firebase.firestore.FieldValue.arrayUnion(
            accountDocRef.id
          ),
        });

        batch
          .commit()
          .then(() => {
            this.routeToTable("added");
          })
          .catch((error) => {
            return error;
          });
      }
    },
  },
};
</script>
