<template>
  <v-container fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('banking_write')">
      <v-col lg="6" offset-lg="3">
        <v-card-title>
          <v-btn to="/bank-statements" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>
            Add New "{{ this.$route.params.transaction_type }}" Transaction
          </h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card>
          <!--New Machine form -->
          <v-form
            @submit.prevent="saveBankTransaction"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row v-if="transaction_added === true">
                <v-col>
                  <v-alert type="success" dense text
                    >Transaction added! Add another one or
                    <router-link to="/bank-statements"
                      >click to return to your bank statement</router-link
                    ></v-alert
                  >
                </v-col>
              </v-row>
              <v-row>
                <!--Bank Account-->
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="bank_account_name"
                    :items="bank_accounts"
                    item-text="bank_account_name"
                    label="Bank Account"
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="bankRules"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <!--Transaction number-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    readonly
                    v-model.number="transaction_number"
                    label="Transaction #"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Transaction Date-->
              <v-row>
                <!--Amount-->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.number="transaction_amount"
                    v-on:keyup.enter="saveBankTransaction"
                    ref="amount"
                    type="number"
                    label="Amount"
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="amountRules"
                    required
                  >
                  </v-text-field>
                </v-col>
                <!--Date Picker-->
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="transaction_date"
                        label="Transaction Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        color="#3d2cdd"
                        :rules="dateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="transaction_date"
                      no-title
                      scrollable
                      color="#3d2cdd"
                      show-adjacent-months
                      :allowed-dates="
                        (date) => date <= new Date().toISOString().substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#ce2458" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <!--Description-->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="transaction_description"
                    label="Description"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Hidden field that captures bank account id-->
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    readonly
                    v-model="bank_account_id"
                    :value="getThisBankAccountDetails()"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    style="visibility: hidden"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/bank-statements" color="#ce2458" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="saveBankTransaction"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Submit
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
  </v-container>
</template>

<script>
//Connect to database
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { showSnackbar } from "../../../globalActions/index";
import {
  formatTransactionNumber,
  getBankAccounts,
  captureUser,
  getThisBankAccountDetails,
  saveBankTransaction,
} from "../data/external_bank_transactions";

export default {
  name: "NewMachine",
  mixins: [mixin_UserRoles],
  components: { NoPermissionsCard },

  data() {
    return {
      valid: false,
      transaction_id: null,
      transaction_type: null,
      loading: null,
      bank_accounts: [],
      bank_account_name: null,
      bank_account_id: null,
      transaction_input_source: null,
      transaction_number: null,
      transaction_allocation_status: "No",
      transaction_date: null,
      transaction_amount: 0,
      transaction_description: null,
      transaction_created_by_id: null,
      transaction_created_by_name: null,
      transaction_added: false,
      attachments: [],
      menu: null,
      date: null,
      bankRules: [(v) => !!v || "You must select a bank account"],
      amountRules: [
        (v) => !!v || "You must enter an amount",
        (v) => v <= 10000000000 || "The amount is too long",
        //  Need to limit input to two floating points
        //  Need to remove 'e' input
      ],
      dateRules: [(v) => !!v || "You must select a date"],
    };
  },
  created() {
    this.getBankAccounts();
    this.captureUser();
    this.formatTransactionNumber();
  },
  conputed: {
    transaction_type() {
      return this.$route.params.transaction_type;
    },
  },
  methods: {
    formatTransactionNumber,
    getBankAccounts,
    captureUser,
    validate() {
      this.$refs.form.validate();
    },
    //Reroute to datatable
    routeToTable() {
      this.loading = false;
      showSnackbar("New transaction added successfully");
      this.transaction_amount = 0;
      this.transaction_description = "";
      this.transaction_added = true;
      this.$nextTick(() => this.$refs.amount.focus());
    },
    saveBankTransaction,
    getThisBankAccountDetails,
  },
};
</script>
