<template>
  <v-menu
    bottom
    open-on-hover
    :offset-y="offset"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="2"
        color="#33cc99"
        dark
        class="ml-10"
        v-bind="attrs"
        v-on="on"
      >
        Add Transaction
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in addOptions"
        :key="index"
        link
        @click="item.action"
      >
        <v-icon left>{{ item.icon }}</v-icon>
        <v-list-item-content>{{ item.title }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      addOptions: [
        {
          title: "Cash In",
          icon: "mdi-plus",
          transaction_type: "Cash In",
          action: () =>
            this.$router.push({
              name: "new-bank-transaction",
              params: { transaction_type: "Cash In" },
            }),
        },
        {
          title: "Cash Out",
          icon: "mdi-plus",
          transaction_type: "Cash Out",
          action: () =>
            this.$router.push({
              name: "new-bank-transaction",
              params: { transaction_type: "Cash Out" },
            }),
        },
        {
          title: "Inter Account Transafer",
          icon: "mdi-plus",
          action: () =>
            this.$router.push({
              path: "new-inter-account-transfer/",
            }),
        },
      ],
      offset: true,
    };
  },
};
</script>
