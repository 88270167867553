import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { firestoreDocProps, newDateToISO, firebaseTimestamp } from "../../../composables/external"
import { showSnackbar } from '../../../globalActions/index'

//  UPDATE BANK ACCOUNTS
//_________________________________________________________
export const updateBankAccount = function () {
  if (this.$refs.form.validate()) {
    this.loading_update = true;
    const batch = db.batch();
    //  Update bank account
    const bankDocRef = db
      .doc(`${this.bank_interface_type}/${this.$route.params.bank_account_id}`)
    batch.update(bankDocRef, firestoreDocProps(this.propArray, this));
    //  Update chart of accounts
    const accountDocRef = db
      .doc(`chart_of_accounts/${this.account_id}`);
    batch.update(accountDocRef, {
      account_name: this.bank_account_name,
    });
    batch
      .commit()
      .then(() => {
        this.loading_update = false;
        this.routeToTable("updated");
      })
      .catch((error) => error);
  }
}

//  DELETE BANK ACCOUNTS
//_________________________________________________________
//  Test to see if bank acounts have transactions
export const testTransactionLength = function () {
  db.collection("bank_transactions")
    .where("bank_account_id", "==", this.$route.params.bank_account_id)
    .limit(1)
    .onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        this.transactions.push(data);
      });
    });
}
//  Confirm whether you want to delete a bank account
export const confirmDeleteBankAccount = function () {
  //  Disable delete if bank account has transactions
  if (this.transactions.length !== 1) return this.delete_bank_account_modal = true
  this.delete_alert_modal = true;
}
//  Delete bank account as well as chart of account item
export const deleteBankAccount = function () {
  this.loading_delete = true;
  const batch = db.batch();
  const bankRef = db
    .collection(`${this.bank_interface_type}`)
    .doc(this.$route.params.bank_account_id);
  const accountRef = db
    .collection("chart_of_accounts")
    .doc(this.account_id);
  const cashDocRef = db
    .collection("chart_of_accounts")
    .doc("UWrVnQN4i3rE0umfoyOR");

  // Delete bank account
  batch.delete(bankRef);
  // Delete ledger from chart of accounts
  batch.delete(accountRef);
  // Remove account ids from sub-ledger array of "Cash" ledger
  batch.update(cashDocRef, {
    sub_ledgers: firebase.firestore.FieldValue.arrayRemove(
      accountRef.id,
    ),
  })
  batch
    .commit()
    .then(() => {
      this.loading_delete = false;
      this.routeToTable("deleted");
    })
    .catch((error) => error);
}

//  Test for primary bank account
export const getBankAccounts = function () {
  //Get bank accounts
  const accountsArray = ["bank_accounts"];
  accountsArray.forEach((item) => {
    db.collection(`${item}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.bank_accounts.push(data);
        });
      });
  });
}

export const setPrimaryBankAccount = function () {
  //  Get an array of all bank accounts
  //  Map the values of primary account booleans
  //  Test to see if the array contains a "true" value
  //  If the value is true disable the switch (there is already a primary account)
  //  If this account is the primary account then enable

  let primaryTestArray = this.bank_accounts.map(
    (accounts) => accounts.primary_bank_account
  );
  primaryTestArray.includes(true) ? true : false;
}
//
export const captureUser = function () {
  //Captures user that created the bank account
  this.bank_account_created_by_id = firebase.auth().currentUser.uid;
  db.collection("users")
    .where("uid", "==", this.bank_account_created_by_id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.bank_account_created_by_name = doc.data().displayName;
      });
    }).catch((error) => error)
}

//
export const addBankAccount = function (value, account_type) {
  if (!this.valid) return;
  this.loading = true;
  const batch = db.batch();
  const bankDocRef = db.collection(value).doc();
  const accountDocRef = db.collection("chart_of_accounts").doc();
  const cashDocRef = db.doc("chart_of_accounts/UWrVnQN4i3rE0umfoyOR");

  const propArray = [
    "bank_account_name",
    "bank_account_status",
    "bank_account_opening_balance",
    "bank_account_created_by_id",
    "bank_account_created_by_name",
  ];
  //  1: Add bank account
  batch.set(bankDocRef, {
    ...firestoreDocProps(propArray, this),
    bank_account_id: bankDocRef.id,
    bank_account_type: account_type,
    bank_account_date_added: newDateToISO(),
    bank_account_timestamp: firebaseTimestamp(),
    account_id: accountDocRef.id,
  });
  //  2: Add to chart of accounts
  batch.set(accountDocRef, {
    account_id: accountDocRef.id,
    account_name: this.bank_account_name,
    account_type: "Assets",
    account_sub_type: "Current Asset",
    financial_statement: "Balance Sheet",
    account_status: "Active",
    parent_account: {
      account_code: "6030",
      account_id: "UWrVnQN4i3rE0umfoyOR",
      account_name: "Cash",
    },
    bank_account_id: bankDocRef.id,
    locked: true,
    is_contra: false,
    account_created_by_id: this.bank_account_created_by_id,
    account_created_by_name: this.bank_account_created_by_name,
    account_created_timestamp: newDateToISO(),
    account_date_created: firebaseTimestamp(),
  });
  // 3: Add account ids to sub-ledger array on "Cash" account
  batch.update(cashDocRef, {
    sub_ledgers: firebase.firestore.FieldValue.arrayUnion(
      accountDocRef.id
    ),
  });

  batch
    .commit()
    .then(() => {
      showSnackbar("New account added successfully!")
      this.$router.push('/bank-accounts')
    })
    .catch((error) => error);
}
//
export const updateAccount = function (value) {
  if (!this.valid) return;
  this.loading_update = true;
  const batch = db.batch();
  //  Update bank account
  const bankDocRef = db
    .doc(`${value}/${this.$route.params.bank_account_id}`)
  batch.update(bankDocRef, {
    bank_account_name: this.bank_account_name,
    bank_account_status: this.bank_account_status,
    bank_account_opening_balance: this.bank_account_opening_balance,
  });
  //  Update chart of accounts
  const accountDocRef = db
    .doc(`chart_of_accounts/${this.account_id}`);
  batch.update(accountDocRef, {
    account_name: this.bank_account_name,
  });
  batch
    .commit()
    .then(() => {
      showSnackbar("Account updated successfully!")
      this.$router.push('/bank-accounts')
    })
    .catch((error) => error);
}
//  Delete bank account as well as chart of account item
export const deleteAccount = function (value) {
  if (this.transactions.length === 1) {
    this.delete_alert_modal = true;
  }
  else {
    this.loading = true;
    const batch = db.batch();
    const bankRef = db
      .collection(value)
      .doc(this.$route.params.bank_account_id);
    const accountRef = db
      .collection("chart_of_accounts")
      .doc(this.account_id);
    const cashDocRef = db
      .collection("chart_of_accounts")
      .doc("UWrVnQN4i3rE0umfoyOR");

    // Delete bank account
    batch.delete(bankRef);
    // Delete ledger from chart of accounts
    batch.delete(accountRef);
    // Remove account ids from sub-ledger array of "Cash" ledger
    batch.update(cashDocRef, {
      sub_ledgers: firebase.firestore.FieldValue.arrayRemove(
        accountRef.id,
      ),
    })
    batch
      .commit()
      .then(() => {
        showSnackbar("Account deleted successfully!")
        this.$router.push('/bank-accounts')
      })
      .catch((error) => error);
  }
}