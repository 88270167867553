const mixin_bank_account_validation_rules = {
    data(){
        return{
            //    Bank Account Form Validation Rules
            valid: false,
            bankRules: [(v) => !!v || "You must select a bank account"],
            accountNumberRules: [(v) => !!v || "You must enter an account number"],
            accountTypeRules: [(v) => !!v || "You must select an account type"],
        }
    }
}

export default mixin_bank_account_validation_rules