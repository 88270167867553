<template>
  <v-menu
    bottom
    open-on-hover
    :offset-y="offset"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="2"
        color="#33cc99"
        dark
        class="ml-10"
        v-bind="attrs"
        v-on="on"
      >
        Add New
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in addOptions"
        :key="index"
        link
        :to="item.to"
      >
        <v-icon left>{{ item.icon }}</v-icon>
        <v-list-item-content>{{ item.title }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    addOptions: [
      {
        title: "Bank Account",
        icon: "mdi-plus",
        to: "/new-bank-account",
      },
      {
        title: "Credit Card Account",
        icon: "mdi-plus",
        to: "/new-credit-card",
      },
      {
        title: "Petty Cash Account",
        icon: "mdi-plus",
        to: "/new-petty-cash-account",
      },
      {
        title: "Speed Point",
        icon: "mdi-plus",
        to: "/new-speed-point",
      },
    ],
    offset: true,
  }),
};
</script>
