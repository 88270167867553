<template>
    <div>
        <BankStatementsTable />
    </div>
</template>

<script>
import BankStatementsTable from '../components/BankStatementsTable'
export default {
    name: 'BankStatements',
    components: {BankStatementsTable}
}
</script>