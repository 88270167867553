<template>
  <div>
    <BankAccountsTable />
  </div>
</template>

<script>
import BankAccountsTable from "../components/BankAccountsTable";
export default {
  name: "BankAccounts",
  components: { BankAccountsTable },
};
</script>
