import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { newDateToISO, firebaseTimestamp, firestoreDocProps } from "../../../composables/external"

// Gets bank transactions from firebase datatbase
//____________________________________________________________
export const getBankTransactions = function () {
  db
    .collection("bank_transactions")
    .onSnapshot((querySnapshot) => {
      this.bank_transactions = []
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        data.is_deleted = false
        data.toggleEdit = false
        this.bank_transactions.push(data);
      })
    })
}
//  Get bank accounts from database
export const getBankAccounts = function () {
  const accountsArray = ["bank_accounts", "speed_points", "petty_cash"];
  accountsArray.forEach((item) => {
    db.collection(`${item}`)
      .where("bank_account_status", "==", "Active")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.bank_accounts.push(data);
        });
      });
  });
}

//  Calculate running balance
//____________________________________________________________

export const calcRunningBalance = function () {
  //Sort the array by date and then transaction_number
  this.bank_transactions.sort((a, b) =>
    (a.transaction_date > b.transaction_date) ? 1
      : (a.transaction_date === b.transaction_date) ?
        (a.transaction_number > b.transaction_number) ? 1
          : -1
        : -1
  );
  //Convert 'Cash Out' transactions to negative numbers
  this.bank_transactions.forEach((transaction) => {
    if (transaction.transaction_type !== "Cash Out") return;
    transaction.transaction_amount = -Math.abs(transaction.transaction_amount)
  })

  //get an array of only the transaction values
  let transactionArray = this.bank_transactions.map(({ transaction_amount }) => {
    return transaction_amount
  })

  //Get account opening balance
  let selectedAccountOpeningBalance = this.bank_accounts.filter((account) => account.bank_account_name === this.bank_accounts_list)[0].bank_account_opening_balance

  //Calculate running balances
  let runningBalance = transactionArray.map((value) => (selectedAccountOpeningBalance += value));
  //Add running balance values to a property in the bank_transactions array
  this.bank_transactions.forEach(function (transaction, index) {
    transaction.transaction_running_balance = runningBalance[index].toFixed(2);
  });
}

//  Date range filters
//____________________________________________________________
export const assignStartDate = function () {
  this.dateFilter.splice(0, 1, this.start_date);
  if (this.end_date < this.start_date) this.dateFilter.reverse();
}
export const assignEndDate = function () {
  this.dateFilter.splice(1, 1, this.end_date);
  if (this.end_date < this.start_date) this.dateFilter.reverse();
}
export const clearEndDate = function () {
  this.dateFilter = [this.start_date, newDateToISO()],
    this.end_date = null;
}
export const clearStartDate = function () {
  this.dateFilter = ["2020-01-01", this.end_date],
    this.start_date = null;
}
export const filteredDate = function () {
  return this.bank_transactions.filter((i) => {
    return (
      this.dateFilter === [] ||
      (i.transaction_date >= this.dateFilter[0] &&
        i.transaction_date <= this.dateFilter[1])
    );
  });
}

//  Bank account filters
//__________________________________________________________
export const filterByBankAccounts = function () {
  let docRef = (this.bank_accounts_list !== null) ?
    db.collection("bank_transactions").where("bank_account_name", "==", this.bank_accounts_list)
    : docRef = db.collection("bank_transactions");

  docRef
    .onSnapshot((querySnapshot) => {
      this.bank_transactions = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.is_deleted = false;
        data.toggleEdit = false;
        this.bank_transactions.push(data);
      })
    })
}
export const clearBankFilter = function () {
  this.bank_accounts_list = null;
  this.filterByBankAccounts();
}

// Dashboard filters
//____________________________________________________________
export const filterTransactions = function () {
  //  Create array of filter functions
  const filters = {

    dateRange: item => item.transaction_date >= this.dateFilter[0] && item.transaction_date <= this.dateFilter[1],
    transactionType: item => item.transaction_type === this.transactionType,
    allocationStatus: item => {
      if (this.allocationStatus === "Yes") {
        return item.transaction_allocation_status !== "No"
      }
      else if (this.allocationStatus === "No") {
        return item.transaction_allocation_status === "No"
      } else return ""
    },
    attachmentStatus: item => {
      if (this.attachmentStatus === "Yes") {
        return item.attachments.some(file => file.file_name !== null)
      }
      if (this.attachmentStatus === "No") {
        return item.attachments.length === 0
      }
      else return ""
    },
    tagSelect: item => {
      if (item.selected_tags) {
        return item.selected_tags.some(tag => tag.name === this.tagSelect)
      } else return ""
    },
    createdDate: item => item.transaction_date_created === this.createdDate,
  }
  const selected = [filters.dateRange]
  //  Set conditions to apply filters to the array
  this.transactionType !== '' ? selected.push(filters.transactionType) : ''
  this.allocationStatus !== '' ? selected.push(filters.allocationStatus) : ''
  this.attachmentStatus !== '' ? selected.push(filters.attachmentStatus) : ''
  this.tagSelect !== '' ? selected.push(filters.tagSelect) : ''
  this.createdDate !== '' ? selected.push(filters.createdDate) : ''

  const output = this.bank_transactions.filter(item => selected.every(filter => filter(item)))
  return output
}

//  Editing & Deleteing transactions
//_____________________________________________________________

export const deleteTransaction = function (item) {
  if (confirm(`Are you sure you want to delete ${item.transaction_number}?`)) {
    const promises = [];
    //Apply styling to deleted item
    item.is_deleted = true;
    // ACCOUNTING LOGIC
    // Ensure the transaction value is deducted from the "Suspense" account
    const date = item.transaction_date.slice(0, -3)
    const suspenseID = (item.transaction_type === "Cash In") ?
      "RSAgQsASdXZKhQRwMZUz" : "44UTKB7Og5aZLcOvaSOl"
    promises.push(
      db.doc(`accounting_totals/${date}/ledger_accounts/${suspenseID}`)
        .update({
          monthly_total: firebase.firestore.FieldValue
            .increment(-Math.abs(item.transaction_amount)),
        })
        .then(() => console.log(`1. Suspense account decreased by --${item.transaction_amount}`))
        .catch((error) => error)
    )
    //  Deete bank transaction
    promises.push(
      db.doc(`bank_transactions/${item.transaction_id}`)
        .delete()
        .then(() => console.log("2. Transaction deleted"))
        .catch((error) => error)
    );
    return Promise
      .all(promises)
      .then(() => console.log("3. All promises returned"))
      .catch((error) => error)

  }
}
export const updateTransaction = function (item) {
  //Apply styling to deleted item
  item.is_updated = true;
  const id = item.transaction_id;
  db.collection("bank_transactions")
    .doc(id)
    .update({
      bank_account_name: item.bank_account_name,
      transaction_amount: item.transaction_amount,
      transaction_description: item.transaction_description,
    });
}

//  Bulk Action related functions
//________________________________________________________________
//
//  Get chart of accounts from database
export const getChartOfAccounts = function () {
  const accountsRef = db
    .collection("chart_of_accounts")
    .orderBy("account_name");
  accountsRef.onSnapshot((querySnapshot) => {
    this.chart_of_accounts = [];
    querySnapshot.forEach((doc) => {
      const data = {
        account_name: doc.data().account_name,
        account_code: doc.data().account_code,
        account_id: doc.data().account_id,
      };
      this.chart_of_accounts.push(data);
    });
  });
}
// Get supplier list from database
export const getSuppliers = function () {
  const suppliersRef = db.collection("suppliers").orderBy("supplier_name");
  suppliersRef.onSnapshot((querySnapshot) => {
    this.suppliers = [];
    querySnapshot.forEach((doc) => {
      const data = {
        supplier_id: doc.data().supplier_id,
        supplier_name: doc.data().supplier_name,
      };
      this.suppliers.push(data);
    });
  });
}
export const BulkDelete = function () {
  if (confirm("Are you sure you want to delete these transactions?")) {
    const promises = [];
    const dateArray = [];
    let uniqueDateArray = [];
    // 1. Delete bank transactions from database
    const deleteBankTransactions = () => {
      const batch = db.batch()
      this.selected_transactions.forEach((item) => {
        const docRef = db.collection('bank_transactions').doc(item.transaction_id)
        if (item.transaction_allocation_status === "No") {
          batch.delete(docRef);
          dateArray.push(item.transaction_date.slice(0, 7))
        }
        item.is_deleted = true;
      })
      promises.push(
        batch.commit()
          .then(() => {
            if (this.bank_transactions.length === 0) {
              this.bulkEdit = false;
              this.selected_transactions = [];
            }
          })
          .catch((error) => error)
      );
    }
    //  ACCOUNTING LOGIC
    // 2.  Identify which months have suspense accounts that need to be updated
    const identifyTransactionMonths = () => {
      const uniqueSet = new Set();
      uniqueDateArray = dateArray.filter((date) => {
        const duplicate = uniqueSet.has(date);
        uniqueSet.add(date);
        return !duplicate;
      });
    }
    // 3. Deduct transaction totals from suspense accounts
    const updateSuspenseAccounts = (type, id) => {
      uniqueDateArray.forEach((date) => {
        // Need to know the credit and debit totals for each month
        const typeArray = this.selected_transactions.filter((item) => item.transaction_type === type && item.transaction_date.slice(0, -3) === date && item.transaction_allocation_status === "No")
        const typeArrayTotal = typeArray.reduce((total, item) => {
          return total + Math.abs(item.transaction_amount);
        }, 0).toFixed(4)
        promises.push(
          db.doc(`accounting_totals/${date}/ledger_accounts/${id}`)
            .update({ monthly_total: firebase.firestore.FieldValue.increment(-Math.abs(typeArrayTotal)) })
            .then(() => {
              this.selected_transactions = []
              console.log(`3. ${date} -- ${type} suspense account deducted by ${typeArrayTotal}`)
            })
        );
      })
    }
    deleteBankTransactions()
    identifyTransactionMonths()
    updateSuspenseAccounts("Cash In", "RSAgQsASdXZKhQRwMZUz")
    updateSuspenseAccounts("Cash Out", "44UTKB7Og5aZLcOvaSOl")
    promises.reduce((accumulatorPromise, nextPromise) => {
      return accumulatorPromise.then(() => {
        return nextPromise;
      });
    }, Promise.resolve());
  }
}

//  Custom toggle all method. Ensures allocated transactions can't be selected for bulk edit
export const selectAllToggle = function (props) {
  if (this.selected_transactions.length != this.bank_transactions.length) {
    this.selected_transactions = [];
    const self = this;
    props.items.forEach((item) => {
      if (item.transaction_allocation_status === "No") {
        self.selected_transactions.push(item);
      }
    });
  } else this.selected_transactions = [];
}
//  Format table rows
export const row_classes = function (item) {
  if (item.is_deleted === true) {
    return "red lighten-4";
  }
  if (item.toggleEdit === true) {
    return "indigo lighten-5";
  }
  if (item.is_updated === true) {
    return "light-green lighten-5";
  }
}
//    Bank statement import related functions
//_______________________________________________________________
//    Generate document IDs
export const makeid = function () {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 21; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

export const getTransactionCounters = function () {
  db.collection("bank_transaction_counters")
    .doc("f4FkXIYnlf0ZhdmJUfcG")
    .onSnapshot((snapshot) => {
      //  Assign values to variables
      this.debit_count = snapshot.data().debit_count;
      this.credit_count = snapshot.data().credit_count;
    });
}
//  Captures user that created the transaction order
export const getCurrentUser = function () {
  this.transaction_created_by_id = firebase.auth().currentUser.uid;
  db.collection("users")
    .doc(this.transaction_created_by_id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.transaction_created_by_name = doc.data().displayName;
      });
    })
    .catch((error) => error);
}
//  Adds bank account id to bank transactions during bulk import
export const getBankAccountDetails = function () {
  const accountsArray = ["bank_accounts", "speed_points"];
  accountsArray.forEach((account) => {
    db.collection(account)
      .where("bank_account_name", "==", this.bank_account_name)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.bank_account_id = data.bank_account_id;
        });
      })
      .catch((error) => error);
  })
}
//  CSV IMPORTS
//_________________________________________________________________
//  1)  Convert uploaded CSV file to JSON
export const parseFileCSV = function (event) {
  let file = event.target.files[0];
  //  Converts CSV into a series of arrays - Each array contains values from one line of the CSV file
  this.$papa.parse(file, {
    header: false,
    complete: this.CSVtoNewBankTransaction,
  });
}

//  2)  Assign CSV data to banking transactions
export const CSVtoNewBankTransaction = function (results) {
  this.import_loading = true;
  this.csv_file_results = results.data;
  let num_debits = 0;
  let num_credits = 0;
  const promises = [];
  const dateArray = [];
  let uniqueDateArray = [];
  const transactionObjectsArray = []
  const monthsArray = [];
  const chartOfAccounts = [];

  //  Identify all the arrays that are bank transactions
  const createTransactionObj = () => {
    this.csv_file_results.forEach((array) => {
      if (!array.includes("HIST")) return;
      //  3) Create a new object for each bank transaction
      const transaction = {
        ...firestoreDocProps(
          [
            "bank_account_name",
            "bank_account_id",
            "transaction_created_by_id",
            "transaction_created_by_name"
          ], this),
        transaction_id: makeid(),
        transaction_date: `${array[1].slice(0, 4)}-${array[1].slice(4, 6)}-${array[1].slice(6, 8)}`,
        transaction_amount: Math.abs(parseFloat(array[3])),
        transaction_description: `${array[4]} - ${array[5]}`,
        transaction_type: (array[3].includes("-")) ? "Cash Out" : "Cash In",
        transaction_allocation_status: "No",
        transaction_input_source: "Import",
        transaction_date_created: newDateToISO(),
        transaction_timestamp: firebaseTimestamp(),
        attachments: [],
      };
      //  4) Format transaction numbers
      if (transaction.transaction_type === "Cash Out") {
        let debit_val = this.debit_count + num_debits;
        transaction.transaction_number =
          debit_val < 1000 ?
            `DB-${String("00000" + debit_val).slice(-4)}` : `DB-${debit_val}`
        num_debits++;

      } else {
        let credit_val = this.credit_count + num_credits;
        transaction.transaction_number =
          credit_val < 1000 ?
            `CD-${String("00000" + credit_val).slice(-4)}` : `CD-${credit_val}`
        num_credits++;
      }
      transactionObjectsArray.push(transaction)
    });
  }
  // SURESWIPE Transactions (PAID IN FROM CUSTOMER)
  const SureSwipeTransactionObj = () => {
    this.csv_file_results.forEach((array) => {
      if (array[4] !== "80604967" || array[11] !== "SETTLED") return;
      //  3) Create a new object for each bank transaction
      const transaction = {
        ...firestoreDocProps(
          [
            "bank_account_id",
            "bank_account_name",
            "transaction_created_by_id",
            "transaction_created_by_name"
          ], this),
        transaction_id: makeid(),
        transaction_date: `${array[0].slice(4, 14)}`,
        transaction_amount: Math.abs(parseFloat(array[10])),
        transaction_description: `BATCH - ${array[3]}`,
        transaction_type: "Cash In",
        transaction_allocation_status: "No",
        transaction_input_source: "Import",
        transaction_date_created: newDateToISO(),
        transaction_timestamp: firebaseTimestamp(),
        attachments: [],
      };
      //  4) Format transaction numbers
      let credit_val = this.credit_count + num_credits;
      transaction.transaction_number =
        credit_val < 1000 ?
          `CD-${String("00000" + credit_val).slice(-4)}` : `CD-${credit_val}`
      num_credits++;

      transactionObjectsArray.push(transaction)
    });
  }
  // SURESWIPE Settlements (PAID OUT TO BANK ACCOUNT)
  const SureSwipeSettlementsObj = () => {
    this.csv_file_results.forEach((array) => {
      if (array[2] === "80604967" && array[1] === "3512191") {
        const transaction = {
          ...firestoreDocProps(
            [
              "bank_account_id",
              "bank_account_name",
              "transaction_created_by_id",
              "transaction_created_by_name"
            ], this),
          transaction_id: makeid(),
          transaction_date: array[0],
          transaction_amount: Math.abs(parseFloat(array[4])),
          transaction_description: `BATCH - ${array[3]}`,
          transaction_type: "Cash Out",
          transaction_allocation_status: "No",
          transaction_input_source: "Import",
          transaction_date_created: newDateToISO(),
          transaction_timestamp: firebaseTimestamp(),
          attachments: [],
        };
        let debit_val = this.debit_count + num_debits;
        transaction.transaction_number =
          debit_val < 1000 ?
            `DB-${String("00000" + debit_val).slice(-4)}` : `DB-${debit_val}`
        num_debits++;

        transactionObjectsArray.push(transaction)
      }
    });
  }


  const addTransactionsToBatch = () => {
    const batch = db.batch()
    transactionObjectsArray.forEach((transaction) => {
      const newDocRef = db.collection("bank_transactions").doc(transaction.transaction_id);
      batch.set(newDocRef, transaction)

      // ACCOUNTING LOGIC
      //  Add transaction_dates to an array that will be used to check
      //  if the "year-month" chart of accounts documents exist
      dateArray.push(transaction.transaction_date.slice(0, 7))
    })
    promises.push(
      batch.commit()
        .then(() => {
          console.log("3. Promise. Batch committed to database")
          this.import_loading = false;
          this.dialog = false;
          this.$refs["csv_upload_input"].value = "";
          this.bank_account_name = null;
        })
        .catch((error) => error)
    )
  }
  const increaseCounters = () => {
    const increaseCount = db
      .collection("bank_transaction_counters").doc("f4FkXIYnlf0ZhdmJUfcG")
    const incrementCredit = firebase.firestore.FieldValue.increment(
      num_credits
    );
    const incrementDebit = firebase.firestore.FieldValue.increment(
      num_debits
    );
    if (num_credits > 0) {
      promises.push(
        increaseCount.update({ credit_count: incrementCredit })
          .then(() => console.log(`4 Promise. Credit counter updated" -- ${num_credits}`))
          .catch((error) => error)
      );
    }
    if (num_debits > 0) {
      promises.push(
        increaseCount.update({ debit_count: incrementDebit })
          .then(() => console.log(`4 Promise. Debit counter updated -- ${num_debits}`))
          .catch((error) => error)
      );
    }
  }
  // ACCOUNTING LOGIC
  const getMonthsFromDatabase = () => {
    const monthRef = db.collection("accounting_totals")
    promises.push(
      monthRef.get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            monthsArray.push(doc.data().id)
          })
          console.log(`1. Months retreived from database == ${monthsArray}`)
        })
        .catch((error) => error)
    );
  }
  const getChartOfAccounts = () => {
    promises.push(
      db.collection("chart_of_accounts")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              ...firestoreDocProps([
                "account_id",
                "account_name",
                "account_type",
                "account_sub_type",
                "financial_statement"
              ], doc.data()),
              is_contra: false,
              monthly_total: 0,
              parent_account: (doc.data().parent_account) ? doc.data().parent_account.account_id : null
            }
            chartOfAccounts.push(data);
          })
          console.log("2. Chart of accounts retreived")
        })
        .catch((error) => error)
    );
  }
  const identifyTransactionMonths = () => {
    const uniqueSet = new Set();
    uniqueDateArray = dateArray.filter((date) => {
      const duplicate = uniqueSet.has(date);
      uniqueSet.add(date);
      return !duplicate;
    });
  }
  const updateSuspenseAccounts = (type, id) => {
    uniqueDateArray.forEach((date) => {
      // Need to know the credit and debit totals for each month
      const typeArray = transactionObjectsArray.filter((item) => item.transaction_type === type && item.transaction_date.slice(0, -3) === date)
      const typeArrayTotal = typeArray.reduce((total, item) => {
        return total + Math.abs(item.transaction_amount);
      }, 0).toFixed(4)
      promises.push(
        db.doc(`accounting_totals/${date}/ledger_accounts/${id}`)
          .update({ monthly_total: firebase.firestore.FieldValue.increment(typeArrayTotal) })
          .then(console.log(`5. ${date} -- ${type} suspense account updated by ${typeArrayTotal}`))
      );
    })
  }
  if (this.bank_account_name === "Standard Bank") {
    createTransactionObj()
  }
  else if (this.bank_account_name === "SureSwipe" && this.csv_file_results[1].length === 15) {
    console.log("Uploading Sureswipe transactions")
    SureSwipeTransactionObj()
  }
  else if (this.bank_account_name === "SureSwipe" && this.csv_file_results[1].length === 5) {
    console.log("Uploading Sureswipe Settlement Batch")
    SureSwipeSettlementsObj()
  }
  //  1. Promise
  getMonthsFromDatabase()
  //  2. Promise
  getChartOfAccounts()
  //  3. Promise
  addTransactionsToBatch()
  // 4. Promise
  increaseCounters()
  identifyTransactionMonths()
  // 5. Promise
  updateSuspenseAccounts("Cash In", "RSAgQsASdXZKhQRwMZUz")
  updateSuspenseAccounts("Cash Out", "44UTKB7Og5aZLcOvaSOl")

  promises.reduce((accumulatorPromise, nextPromise) => {
    return accumulatorPromise.then(() => {
      return nextPromise;
    });
  }, Promise.resolve());

}