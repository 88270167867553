<template>
  <v-container fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('banking_write')">
      <v-col lg="6" offset-lg="3">
        <FormHeader v-bind="newBankAccountHeader" />
        <v-card>
          <!--New Machine form -->
          <v-form
            @submit.prevent="saveBankAccount"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selectedBank"
                    :items="bank_list"
                    item-text="name"
                    return-object
                    counter="50"
                    label="Select Bank"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="bankRules"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model.number="bank_account_number"
                    type="number"
                    counter="20"
                    label="Account Number"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="accountNumberRules"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model.number="bank_account_branch_code"
                    :value="getBankInfo()"
                    type="number"
                    counter="50"
                    label="Branch Code"
                    readonly
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="bank_account_swift_code"
                    counter="50"
                    label="Swift Code"
                    readonly
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="bank_account_type"
                    label="Account Type"
                    :items="[
                      'Cheque Account',
                      'Savings Account',
                      'Money Market Account',
                      'Fixed / Deposit Account',
                    ]"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="accountTypeRules"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="bank_account_status"
                    label="Status"
                    :items="['Active', 'Inactive']"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!--Opening Balance-->
              <v-row class="mt-3">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model.number="bank_account_opening_balance"
                    type="number"
                    label="Opening Balance"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/bank-accounts" color="#ce2458" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="saveBankAccount"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Submit
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
  </v-container>
</template>

<script>
//Connect to database
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import FormHeader from "../../../components/Forms/FormHeader";
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import mixin_bank_account_validation_rules from "../mixins/mixin_bank_account_validation_rules";
import store from "../store/store";
import { mapState } from "vuex";
import { showSnackbar } from "../../../globalActions/index";

export default {
  name: "NewMachine",
  mixins: [mixin_UserRoles, mixin_bank_account_validation_rules],
  components: { FormHeader, NoPermissionsCard },

  data() {
    return {
      //Initial form values
      bank_account_id: null,
      account_id: null,
      bank_account_name: null,
      bank_account_number: null,
      bank_account_branch_code: null,
      bank_account_swift_code: null,
      bank_account_type: null,
      bank_account_status: "Active",
      primary_bank_account: false,
      bank_account_opening_balance: 0,
      bank_account_created_by_id: null,
      bank_account_created_by_name: null,
      bank_account_date_added: null,
      bank_account_timestamp: null,
      loading: null,
      selectedBank: null,
      bank_accounts: [],

      //List of banks
      bank_list: [
        { name: "Absa Bank Limited", branch: "632005", swift: "ABSAZAJJXXX" },
        {
          name: "African Bank Limited",
          branch: "430000",
          swift: "AFRCZAJJXXX",
        },
        {
          name: "Bidvest Bank Limited",
          branch: "462005",
          swift: "BIDBZAJJXXX",
        },
        {
          name: "Capitec Bank Limited",
          branch: "470010",
          swift: "CABLZAJJXXX",
        },
        { name: "Discovery Bank Limited", branch: "679000", swift: "DISCZAJJ" },
        { name: "First National Bank", branch: "250655", swift: "FIRNZAJJ" },
        { name: "FirstRand Bank", branch: "250655", swift: "FIRNZAJJRSL" },
        {
          name: "Grindrod Bank Limited",
          branch: "223626",
          swift: "GRIDZAJJXXX",
        },
        {
          name: "Investec Bank Limited",
          branch: "580105",
          swift: "IVESZAJJXXX",
        },
        {
          name: "Merchantile Bank Limited",
          branch: "450105",
          swift: "LISAZAJJXXX",
        },
        { name: "Nedbank Limited", branch: "198765", swift: "NEDSZAJJXXX" },
        { name: "Old Mutual", branch: "462005", swift: "OMAMZAJCXXX" },
        { name: "Sasfin Bank Limited", branch: "683000", swift: "SASFZAJJXXX" },
        { name: "Standard Bank", branch: "051001", swift: "SBZAZAJJ" },
        { name: "Tyme Bank", branch: "678910", swift: "CBZAZAJJ" },
      ],
    };
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    newBankAccountHeader: (state) => state.newBankAccountHeader,
  }),
  created() {
    //Captures user that created the sales order
    this.bank_account_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
      .where("uid", "==", this.bank_account_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.bank_account_created_by_name = doc.data().displayName;
        });
      });
  },
  methods: {
    //Reroute to bank account page after database has been updated
    routeToTable(message) {
      this.loading_update = false;
      showSnackbar(`Bank account ${message} successfully`);
      this.$router.push("/bank-accounts");
    },
    validate() {
      this.$refs.form.validate();
    },
    getBankInfo() {
      if (this.selectedBank != null) {
        (this.bank_account_name = this.selectedBank.name),
          (this.bank_account_branch_code = this.selectedBank.branch);
        this.bank_account_swift_code = this.selectedBank.swift;
      }
    },

    saveBankAccount() {
      //Form validation --> ensures required fields aren't blank before continuing further
      if (this.$refs.form.validate()) {
        this.loading = true;

        const batch = db.batch();

        //  Step 1: Add the bank account doc to collection

        const bankDocRef = db.collection("bank_accounts").doc();
        const accountDocRef = db.collection("chart_of_accounts").doc();
        const cashDocRef = db
          .collection("chart_of_accounts")
          .doc("UWrVnQN4i3rE0umfoyOR");
        batch.set(bankDocRef, {
          //Populated by the v-model values in the form
          bank_account_id: bankDocRef.id,
          bank_account_name: `${this.bank_account_name} - ${this.bank_account_number}`,
          bank_account_number: this.bank_account_number,
          bank_account_branch_code: this.bank_account_branch_code,
          bank_account_swift_code: this.bank_account_swift_code,
          bank_account_type: this.bank_account_type,
          bank_account_status: this.bank_account_status,
          primary_bank_account: this.primary_bank_account,
          bank_account_opening_balance: this.bank_account_opening_balance,
          bank_account_created_by_id: this.bank_account_created_by_id,
          bank_account_created_by_name: this.bank_account_created_by_name,
          bank_account_date_added: new Date().toISOString().slice(0, 10),
          bank_account_timestamp:
            firebase.firestore.FieldValue.serverTimestamp(),
          //  Chart of accounts references
          account_id: accountDocRef.id,
        });
        //  Step 2: Add the bank account to chart of accounts
        batch.set(accountDocRef, {
          account_id: accountDocRef.id,
          account_name: `${this.bank_account_name} - ${this.bank_account_number}`,
          account_code: "",
          account_type: "Assets",
          account_sub_type: "Current Asset",
          financial_statement: "Balance Sheet",
          account_status: "Active",
          parent_account: {
            account_code: "6030",
            account_id: "UWrVnQN4i3rE0umfoyOR",
            account_name: "Cash",
          },
          bank_account_id: bankDocRef.id,
          locked: true,
          is_contra: false,
          account_created_by_id: this.bank_account_created_by_id,
          account_created_by_name: this.bank_account_created_by_name,
          account_created_timestamp:
            firebase.firestore.FieldValue.serverTimestamp(),
          account_date_created: new Date().toISOString().slice(0, 10),
        });
        // step 4: Add account ids to sub-ledger array on "Cash" account
        batch.update(cashDocRef, {
          sub_ledgers: firebase.firestore.FieldValue.arrayUnion(
            accountDocRef.id
          ),
        });
        batch.commit().then(() => {
          //Reroute to bank accounts table page after database has been updated
          this.routeToTable("added");
        });
      }
    },
  },
};
</script>
