//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: 'secondary',
    btn_ml_10: 'ml-10',
}

//DATA TABLE HEADERS
//Bank Accounts table
const bankAccountsHeaders = [
    { text: 'Edit', value: 'bank_account_id', align: 'start' },
    { text: 'Name', sortable: true, value: 'bank_account_name' },
    { text: 'Account Number', value: 'bank_account_number' },
    { text: 'Branch Code', value: 'bank_account_branch_code' },
    { text: 'Type', value: 'bank_account_type' },
    { text: 'Opening Balance', value: 'bank_account_opening_balance' },
    { text: 'Status', value: 'bank_account_status' },
]
//Petty Cash  table
const pettyCashHeaders = [
    { text: 'Edit', value: 'bank_account_id', align: 'start' },
    { text: 'Account Name', value: 'bank_account_name' },
    { text: 'Opening Balance', value: 'bank_account_opening_balance' },
    { text: 'Status', value: 'bank_account_status' },
]
//Credit Cards table
const creditCardHeaders = [
    { text: 'Edit', value: 'credit_card_id', align: 'start' },
    { text: 'Account Name', value: 'credit_card_number' },
    { text: 'Opening Balance', value: 'bank_account_opening_balance' },
    { text: 'Status', value: 'credit_card_status' },
]
//Speed Point table
const speedPointHeaders = [
    { text: 'Edit', value: 'bank_account_id', align: 'start' },
    { text: 'Name', value: 'bank_account_name' },
    { text: 'Opening Balance', value: 'bank_account_opening_balance' },
    { text: 'Status', value: 'bank_account_status' },
]
//Bank statements table
const bankStatementHeaders = [
    { text: 'Date', value: 'transaction_date', align: 'start' },
    { text: 'Transaction #', value: 'transaction_number' },
    { text: 'Bank Account', value: 'bank_account_name' },
    { text: 'Allocated', value: 'transaction_allocation_status' },
    { text: 'Description', value: 'transaction_description' },
    { text: 'Type', value: 'transaction_type' },
    { text: 'Cash Out', value: 'transaction_amount_cash_out', align: 'right' },
    { text: 'Cash In', value: 'transaction_amount_cash_in', align: 'right' },
    { text: 'Balance', value: 'transaction_running_balance', align: 'right' },
    { text: "Tags", value: 'selected_tags', align: 'right' },
    { text: 'Actions', value: 'transaction_id', align: 'right' },
    { text: '', value: 'transaction_amount', align: ' d-none' }, //Ensures we still have search functionality
]

//----------------------------------------------
//FORM HEADERS
//New Bank Account Form Header
const newBankAccountHeader = {
    form_header_title: 'Add New Bank Account',
    form_header_to: '/bank-accounts',
}
const editBankAccountHeader = {
    form_header_title: 'Edit Bank Account',
    form_header_to: '/bank-accounts',
}
const newSpeedPointHeader = {
    form_header_title: 'Add New Speed Point',
    form_header_to: '/bank-accounts',
}
const editSpeedPointHeader = {
    form_header_title: 'Edit Speed Point',
    form_header_to: '/bank-accounts',
}


//EXPORTED to the Vuex state block
export {
    //BUTTONS
    primaryCTA, secondaryCTA,

    //DATA TABLE HEADERS
    bankAccountsHeaders, creditCardHeaders, pettyCashHeaders,
    bankStatementHeaders, speedPointHeaders,

    //FORM HEADERS
    newBankAccountHeader, editBankAccountHeader, newSpeedPointHeader,
    editSpeedPointHeader,
}