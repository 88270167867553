<template>
  <div>
    <v-container fluid class="px-10">
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Bank Statements</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>
            <!--CTA button-->
            <NewTransactionButton
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('banking_write')"
            />
            <v-spacer></v-spacer>

            <v-dialog v-model="inter_account_modal"> Testing </v-dialog>

            <!--More Options Button-->
            <v-menu
              bottom
              open-on-hover
              :offset-y="offset"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined left v-bind="attrs" v-on="on">
                  More Options
                  <v-icon right small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <!--More Options Button Child Component-->
              <bank-satements-more-options-button
                :bulkEdit="bulkEdit"
                :selected_transactions="selected_transactions"
                :dialog="dialog"
                @changeBulkEdit="ChangeEdit($event)"
                @closeBulkEdit="CloseBulkEdit($event)"
                @triggerBulkDelete="BulkDelete($event)"
                @triggerBulkCreateJournalEntries="
                  BulkCreateJournalEntries($event)
                "
                @triggerUploadModal="OpenUploadModal($event)"
              >
              </bank-satements-more-options-button>
            </v-menu>

            <!--Statement Upload Button-->
            <v-dialog v-model="dialog" width="500">
              <!--Upload Form-->
              <v-card v-if="!import_loading">
                <v-card-title>
                  Upload Bank Statement
                  <v-spacer></v-spacer>
                  <!--Close button-->
                  <v-btn icon right @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-form class="pa-3" ref="form">
                  <v-container>
                    <v-row>
                      <!--Bank Account-->
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="bank_account_name"
                          :items="bank_accounts"
                          item-text="bank_account_name"
                          label="Select Bank Account"
                          required
                          outlined
                          dense
                          color="#3d2cdd"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <!--CSV file upload button-->
                        <input
                          :disabled="this.bank_account_name === null"
                          type="file"
                          accept=".csv, text/csv"
                          label="Upload CSV File"
                          ref="csv_upload_input"
                          @change="parseFileCSV"
                        />
                      </v-col>
                    </v-row>
                    <!--Hidden field that captures bank account id-->
                    <v-row style="visibility: hidden">
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          readonly
                          v-model="bank_account_id"
                          :value="getBankAccountDetails()"
                          required
                          outlined
                          dense
                          color="#3d2cdd"
                          style="visibility: hidden"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
              <!--Loading Sate-->
              <v-card
                v-else
                height="296px"
                max-height="296"
                class="text-center"
              >
                <v-card-title class="mb-10">
                  Upload... Please Wait
                </v-card-title>
                <v-progress-circular
                  :size="100"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-card>
            </v-dialog>
          </v-card-title>
        </v-col>
      </v-row>

      <!--Table filters-->
      <v-row class="mb-3">
        <v-col md="12" lg="12">
          <v-card class="px-5 pt-5" elevation="3">
            <v-row>
              <v-col md="2" sm="2">
                <h4>Bank Account</h4>
              </v-col>
              <v-col md="2" sm="2">
                <h4>Search</h4>
              </v-col>
              <v-col md="4" sm="4">
                <h4>Date Range</h4>
              </v-col>
              <v-col md="2" sm="2">
                <h4>Transaction Type</h4>
              </v-col>
              <v-col md="2" sm="2">
                <h4>More Filters</h4>
              </v-col>
            </v-row>
            <v-row>
              <!--Bank Account Filter-->
              <v-col md="2" sm="2">
                <bank-statements-account-filter
                  v-model="bank_accounts_list"
                  :bank_accounts="bank_accounts"
                  @triggerAccountsFilter="filterByBankAccounts($event)"
                  @triggerClearAccountsFilter="clearBankFilter($event)"
                >
                </bank-statements-account-filter>
              </v-col>
              <!--Search Bar-->
              <v-col md="2" sm="2">
                <v-text-field
                  v-model="search"
                  label="Search"
                  outlined
                  flat
                  solo
                  dense
                  clearable
                  append-icon="mdi-magnify"
                >
                </v-text-field>
              </v-col>
              <!--Date Range-->
              <!--Start Date Picker-->
              <v-col lg="2" md="2">
                <v-menu
                  ref="start_date_menu"
                  v-model="start_date_menu"
                  :close-on-content-click="true"
                  :return-value.sync="date"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="start_date"
                      label="Start Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      color="#3d2cdd"
                      clearable
                      @click:clear="clearStartDate()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    :max="end_date"
                    @change="assignStartDate()"
                    title="End Date"
                    color="#3d2cdd"
                    :allowed-dates="
                      (date) => date <= new Date().toISOString().substr(0, 10)
                    "
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="#ce2458"
                      @click="start_date_menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="#33cc99"
                      @click="$refs.start_date_menu.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--End Date Picker-->
              <v-col lg="2" md="2">
                <v-menu
                  ref="end_date_menu"
                  v-model="end_date_menu"
                  :close-on-content-click="true"
                  :return-value.sync="date"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="end_date"
                      label="End Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      clearable
                      @click:clear="clearEndDate()"
                      color="#3d2cdd"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    :min="start_date"
                    @change="assignEndDate()"
                    title="End Date"
                    color="#3d2cdd"
                    :allowed-dates="
                      (date) => date <= new Date().toISOString().substr(0, 10)
                    "
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="#ce2458" @click="end_date_menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#33cc99" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--Transaction Type Filters-->
              <v-col md="2" sm="2">
                <v-select
                  :items="['Cash In', 'Cash Out']"
                  v-model="transactionType"
                  label="Transaction Type"
                  outlined
                  flat
                  solo
                  dense
                  append-icon="mdi-filter-variant"
                >
                </v-select>
              </v-col>
              <!--More Filters-->
              <v-col md="2" sm="2">
                <v-switch
                  v-model="more_filters"
                  label="More Filters"
                  color="indigo"
                ></v-switch>
              </v-col>
            </v-row>
            <!--More filters-->
            <div v-if="more_filters === true">
              <v-row class="pt-3">
                <v-col md="3" sm="3">
                  <h4>Allocation Status</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Has Attachments</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Tags</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Date Created</h4>
                </v-col>
              </v-row>
              <!--Allocation Status-->
              <v-row>
                <v-col md="3" sm="3">
                  <v-select
                    :items="['Yes', 'No']"
                    v-model="allocationStatus"
                    label="Allocated"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                  >
                  </v-select>
                </v-col>
                <!--Attachments-->
                <v-col md="3" sm="3">
                  <v-select
                    :items="[
                      { text: 'Yes', value: 'Yes' },
                      { text: 'No', value: 'No' },
                    ]"
                    item-text="text"
                    item-value="value"
                    v-model="attachmentStatus"
                    label="Attachments"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                  >
                  </v-select>
                </v-col>
                <!--Tag Selection-->
                <v-col md="3" sm="3">
                  <v-autocomplete
                    :items="this.tags"
                    item-text="name"
                    item-value="name"
                    v-model="tagSelect"
                    label="Select Tag"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                  >
                  </v-autocomplete>
                </v-col>
                <!--Created Date Picker-->
                <v-col lg="2" md="2">
                  <v-menu
                    ref="created_date_menu"
                    v-model="created_date_menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="createdDate"
                        label="Created Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                        @click:clear="createdDate = ''"
                        color="#3d2cdd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="createdDate"
                      title="Created Date"
                      color="#3d2cdd"
                      :allowed-dates="
                        (date) => date <= new Date().toISOString().substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="#ce2458"
                        @click="end_date_menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!--End of table filters-->

      <!--Table skeleton loader-->
      <v-row v-if="firstLoad">
        <v-col>
          <v-sheet elevation="4">
            <v-skeleton-loader
              :loading="loading_user"
              type="table"
              max-height="500"
            >
            </v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <!--End of skeleton loader-->
      <v-row
        v-if="!firstLoad"
        v-show="this.user_roles_list.flat().includes('banking_read')"
      >
        <v-col>
          <v-sheet elevation="4">
            <v-data-table
              :headers="bankStatementHeaders"
              :items="filterTransactions"
              :search="search"
              :sort-by="['transaction_date', 'transaction_number']"
              :sort-desc="[true, true]"
              multi-sort
              :items-per-page="1000"
              group-by="transaction_date"
              :group-desc="true"
              dense
              :item-class="row_classes"
              :show-select="bulkEdit"
              v-model="selected_transactions"
              item-key="transaction_id"
              @toggle-select-all="selectAllToggle"
            >
              <!--Group header-->
              <template v-slot:group.header="{ items }">
                <th colspan="11" class="blue-grey lighten-5">
                  {{
                    new Date(items[0].transaction_date).toLocaleDateString(
                      "en-GB",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )
                  }}
                </th>
              </template>
              <!--Edit instruction-->
              <template
                v-slot:item.transaction_id="{ item }"
                v-if="this.user_roles_list.flat().includes('banking_write')"
              >
                <!--Transaction Actions Menu-->
                <div v-if="!item.toggleEdit">
                  <!--Attachments-->
                  <template v-if="item.attachments.length > 0">
                    <v-btn
                      color="#3d2cdd"
                      icon
                      @click.stop="
                        $set(viewAttachmentsDialog, item.transaction_id, true)
                      "
                    >
                      <v-icon small>mdi-file-outline</v-icon>
                      <span style="font-size: 0.8em">{{
                        item.attachments.length
                      }}</span>
                    </v-btn>
                    <v-dialog
                      v-model="viewAttachmentsDialog[item.transaction_id]"
                      scrollable
                      max-width="700"
                      :key="item.transaction_id"
                    >
                      <transaction-attachments-dialog
                        :value="item"
                        @passAllocateTransaction="
                          $set(
                            transactionAllocationDialog,
                            item.transaction_id,
                            true
                          );
                          $set(
                            viewAttachmentsDialog,
                            item.transaction_id,
                            false
                          );
                        "
                      >
                      </transaction-attachments-dialog>
                    </v-dialog>
                  </template>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <!--Menu items-->
                    <v-list>
                      <!--View Transaction-->
                      <v-list-item
                        :to="{
                          name: 'view-bank-transaction',
                          params: { transaction_id: item.transaction_id },
                        }"
                      >
                        <v-list-item-title>
                          <v-icon left small>mdi-eye</v-icon>
                          View Transaction
                        </v-list-item-title>
                      </v-list-item>
                      <!--Edit Transaction-->
                      <v-list-item @click="item.toggleEdit = !item.toggleEdit">
                        <v-list-item-title>
                          <v-icon left small>mdi-pencil</v-icon>
                          Edit Transaction
                        </v-list-item-title>
                      </v-list-item>
                      <!--Delete Transaction-->
                      <v-list-item @click="deleteTransaction(item)">
                        <v-list-item-title>
                          <v-icon left small>mdi-delete</v-icon>
                          Delete Transaction
                        </v-list-item-title>
                      </v-list-item>
                      <!--Attachments-->
                      <v-list-item
                        @click.stop="
                          $set(viewAttachmentsDialog, item.transaction_id, true)
                        "
                      >
                        <v-dialog
                          v-model="viewAttachmentsDialog[item.transaction_id]"
                          scrollable
                          max-width="700"
                          :key="item.transaction_id"
                        >
                          <transaction-attachments-dialog :value="item">
                          </transaction-attachments-dialog>
                        </v-dialog>
                        <v-list-item-title>
                          <v-icon left small>mdi-attachment</v-icon>
                          Attachments
                          <v-chip
                            v-if="item.attachments.length > 0"
                            small
                            right
                            >{{ item.attachments.length }}</v-chip
                          >
                        </v-list-item-title>
                      </v-list-item>
                      <!--Tags-->
                      <v-list-item
                        @click.stop="
                          $set(viewTagDialog, item.transaction_id, true)
                        "
                      >
                        <v-dialog
                          v-model="viewTagDialog[item.transaction_id]"
                          scrollable
                          max-width="700"
                          :key="item.transaction_id"
                        >
                          <transaction-tag-dialog :item="item">
                          </transaction-tag-dialog>
                        </v-dialog>
                        <v-list-item-title>
                          <v-icon left small>mdi-tag-multiple</v-icon>
                          Tag Transaction
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <!---->
                <div v-else>
                  <v-btn icon x-small @click="updateTransaction(item)">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    x-small
                    @click="item.toggleEdit = !item.toggleEdit"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </template>
              <!--Disables allocated transactions from bulk edit-->
              <template
                v-slot:item.data-table-select="{ item, isSelected, select }"
              >
                <v-simple-checkbox
                  :value="isSelected"
                  :ripple="false"
                  :readonly="item.transaction_allocation_status !== 'No'"
                  :disabled="item.transaction_allocation_status !== 'No'"
                  @input="select($event)"
                ></v-simple-checkbox>
              </template>
              <!--Truncate bank account name -->
              <template v-slot:item.bank_account_name="{ item }">
                <div v-if="item.toggleEdit === false">
                  <tr>
                    <td class="truncate">
                      {{ item.bank_account_name }}
                    </td>
                  </tr>
                </div>

                <!--Edit bank account-->
                <div v-else>
                  <tr>
                    <v-autocomplete
                      v-model="item.bank_account_name"
                      :items="bank_accounts"
                      item-text="bank_account_name"
                      :value="item.bank_account_name"
                      dense
                    >
                    </v-autocomplete>
                  </tr>
                </div>
              </template>
              <!--View transaction details-->
              <template v-slot:item.transaction_number="props">
                <a
                  class="text-decoration-none"
                  @click.stop="
                    $set(viewTransactionDialog, props.item.transaction_id, true)
                  "
                >
                  {{ props.item.transaction_number }}
                </a>
                <v-dialog
                  v-model="viewTransactionDialog[props.item.transaction_id]"
                  scrollable
                  max-width="700"
                  :key="props.item.transaction_id"
                >
                  <bank-statements-view-transaction-modal :value="props.item">
                  </bank-statements-view-transaction-modal>
                </v-dialog>
              </template>
              <!--Allocate Transaction-->
              <template v-slot:item.transaction_allocation_status="props">
                <div v-if="props.item.transaction_allocation_status === 'No'">
                  <a
                    class="text-decoration-none"
                    @click.stop="
                      $set(
                        transactionAllocationDialog,
                        props.item.transaction_id,
                        true
                      )
                    "
                  >
                    {{ props.item.transaction_allocation_status }}
                  </a>
                  <!--Allocation Modal-->
                  <AllocationModal
                    @passModalClose="
                      $set(
                        transactionAllocationDialog,
                        props.item.transaction_id,
                        $event
                      );
                      componentKey += 1;
                    "
                    :key="componentKey"
                    :transaction="props.item"
                    :transactionAllocationDialog="transactionAllocationDialog"
                  />
                </div>
                <!--View allocated transaction-->
                <div v-else>
                  <a
                    class="text-decoration-none"
                    @click.stop="
                      $set(
                        viewAllocatedTransactionDialog,
                        props.item.transaction_id,
                        true
                      )
                    "
                  >
                    {{ props.item.transaction_allocation_status }}
                  </a>
                  <v-dialog
                    v-model="
                      viewAllocatedTransactionDialog[props.item.transaction_id]
                    "
                    scrollable
                    max-width="850"
                    :key="props.item.transaction_id"
                  >
                    <bank-statements-view-allocated-transaction-modal
                      :value="props.item"
                    >
                    </bank-statements-view-allocated-transaction-modal>
                  </v-dialog>
                </div>
              </template>
              <!-- Description -->
              <template v-slot:item.transaction_description="{ item }">
                <div v-if="!item.toggleEdit">
                  <tr>
                    <td class="truncate_description">
                      {{ item.transaction_description }}
                    </td>
                  </tr>
                </div>
                <!--Edit description-->
                <div v-else-if="item.toggleEdit">
                  <v-text-field
                    v-model="item.transaction_description"
                    label="Description"
                  >
                  </v-text-field>
                </div>
              </template>

              <!--Format transaction type-->
              <template v-slot:item.transaction_type="{ item }">
                <div v-if="!item.toggleEdit">
                  <v-icon
                    x-small
                    left
                    :class="[
                      item.transaction_type === 'Cash Out',
                      item.transaction_type === 'Cash In'
                        ? 'green--text'
                        : 'red--text mdi-rotate-180',
                    ]"
                  >
                    mdi-triangle</v-icon
                  >
                  {{ item.transaction_type }}
                </div>
              </template>

              <!--Cash In Column-->
              <template v-slot:item.transaction_amount_cash_in="{ item }">
                <div v-if="!item.toggleEdit">
                  <span v-if="item.transaction_type == 'Cash In'">{{
                    new Intl.NumberFormat("en-GB", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(item.transaction_amount)
                  }}</span>
                </div>
                <div
                  v-else-if="
                    item.toggleEdit && item.transaction_type == 'Cash In'
                  "
                >
                  <v-text-field
                    v-model.number="item.transaction_amount"
                    label="Amount"
                  >
                  </v-text-field>
                </div>
              </template>
              <!--Cash Out Column-->
              <template v-slot:item.transaction_amount_cash_out="{ item }">
                <div v-if="!item.toggleEdit">
                  <span v-if="item.transaction_type == 'Cash Out'"
                    >({{
                      new Intl.NumberFormat("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(Math.abs(item.transaction_amount))
                    }})</span
                  >
                </div>
                <div
                  v-else-if="
                    item.toggleEdit && item.transaction_type == 'Cash Out'
                  "
                >
                  <v-text-field
                    v-model.number="item.transaction_amount"
                    type="number"
                    step="0.01"
                    label="Amount"
                  >
                  </v-text-field>
                </div>
              </template>
              <!--Balance Column-->
              <template v-slot:item.transaction_running_balance="{ item }">
                <div v-if="!item.toggleEdit">
                  <span
                    v-if="item.transaction_running_balance < 0"
                    class="red--text"
                  >
                    ({{
                      new Intl.NumberFormat("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                        .format(item.transaction_running_balance)
                        .slice(1)
                    }})
                  </span>
                  <span
                    v-else-if="item.transaction_running_balance >= 0"
                    class="green--text"
                  >
                    {{
                      new Intl.NumberFormat("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(item.transaction_running_balance)
                    }}
                  </span>
                </div>
              </template>
              <!--Tags-->
              <template v-slot:item.selected_tags="props">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-if="
                        props.item.selected_tags &&
                        props.item.selected_tags.length > 0
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="deep-purple" small>mdi-tag</v-icon>
                      {{ props.item.selected_tags.length }}
                    </span>
                  </template>
                  <span
                    v-for="tag in props.item.selected_tags"
                    v-bind:key="tag.name"
                    ><v-icon left small dark>{{ tag.icon }}</v-icon
                    >{{ tag.name }} <br
                  /></span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>

      <BulkCreateJournalEntriesModal
        @closeDialog="closeBulkJournalEntriesDialog($event)"
        @unselectTransactions="unselectTransactions($event)"
        :bulk_journal_entries_dialog="bulk_journal_entries_dialog"
        :selected_transactions="
          selected_transactions.filter(
            (i) => i.transaction_allocation_status === 'No'
          )
        "
      />
    </v-container>
  </div>
</template>

<script>
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import mixin_tags from "../mixins/mixin_tags";
import NewTransactionButton from "./NewTransactionButton";
import store from "../store/store";
import { mapState } from "vuex";
import {
  getBankTransactions,
  getBankAccounts,
  getTransactionCounters,
  getCurrentUser,
  calcRunningBalance,
  assignStartDate,
  assignEndDate,
  clearEndDate,
  clearStartDate,
  filterTransactions,
  filteredDate,
  filterByBankAccounts,
  clearBankFilter,
  deleteTransaction,
  updateTransaction,
  BulkDelete,
  selectAllToggle,
  row_classes,
  getBankAccountDetails,
  parseFileCSV,
  CSVtoNewBankTransaction,
} from "../data/external_bank_statements";
export default {
  name: "BankSatementsTable",
  components: {
    NewTransactionButton,
    BankSatementsMoreOptionsButton: () =>
      import(
        /* webpackPrefetch: true */ "./BankSatementsMoreOptionsButton.vue"
      ),
    BankStatementsAccountFilter: () =>
      import(/* webpackPrefetch: true */ "./BankStatementsAccountFilter.vue"),
    BankStatementsViewTransactionModal: () =>
      import(
        /* webpackPrefetch: true */ "./BankStatementsViewTransactionModal.vue"
      ),

    //  Transaction Allocation components
    AllocationModal: () =>
      import(
        /* webpackPrefetch: true */ "../components/AllocationComponents/AllocationModal.vue"
      ),

    BankStatementsViewAllocatedTransactionModal: () =>
      import(
        /* webpackPrefetch: true */ "./BankStatementsViewAllocatedTransactionModal.vue"
      ),
    TransactionAttachmentsDialog: () =>
      import("./TransactionAttachmentsDialog.vue"),
    TransactionTagDialog: () => import("./TransactionTagDialog.vue"),
    BulkCreateJournalEntriesModal: () =>
      import("./BulkCreateJournalEntriesModal.vue"),
  },
  mixins: [mixin_UserRoles, mixin_tags],
  data() {
    return {
      pagination: {},
      search: this.searchQuery(),
      bank_transactions: [],
      transactionAllocationDialog: {},
      bank_accounts: [],
      speed_points: [],
      selectedBankAccount: "",
      menu: null,
      date: null,
      csv_file_results: {},
      invalidCSV: false,
      debit_count: null,
      credit_count: null,
      dialog: false,
      bank_account_name: null,
      bank_account_id: null,
      bank_account_opening_balance: null,
      transaction_created_by_id: null,
      transaction_created_by_name: null,
      bulkEdit: false,
      selected_transactions: [],
      offset: true,
      dateFilter: [this.startDate(), new Date().toISOString().slice(0, 10)],
      more_filters: false,
      transactionType: "",
      allocationStatus: "",
      attachmentStatus: "",
      createdDate: "",
      tagSelect: "",
      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      created_date_menu: null,
      bank_accounts_list: null,
      viewTransactionDialog: {},
      viewAllocatedTransactionDialog: {},
      viewAttachmentsDialog: {},
      viewTagDialog: {},
      componentKey: 0,
      inter_account_modal: false,
      bulk_journal_entries_dialog: false,
      import_loading: false,
    };
  },
  // Gets bank transactions from firebase datatbase
  created() {
    this.getBankTransactions();
    this.getTransactionCounters();
    this.getCurrentUser();
    this.getBankAccounts();
    //  Opens the transaction preview modal if a user has clicked through
    //  to view a transaction from another UI
    const openModalOnSearchQuery = () => {
      if (!this.$route.query.transaction_id) return;
      this.$set(
        this.viewTransactionDialog,
        this.$route.query.transaction_id,
        true
      );
    };
    openModalOnSearchQuery();
  },
  beforeUpdate() {
    if (this.bank_accounts_list) {
      this.calcRunningBalance();
    }
  },
  store,
  computed: mapState({
    bankStatementHeaders: (state) => state.bankStatementHeaders,
    filteredDate,
    filterTransactions,
  }),
  watch: {
    search(newValue, oldValue) {
      if (newValue === oldValue) return;
      console.log("Value changed");
    },
  },
  methods: {
    getBankAccounts,
    getBankTransactions,
    getTransactionCounters,
    getCurrentUser,
    calcRunningBalance,
    assignStartDate,
    assignEndDate,
    clearEndDate,
    clearStartDate,
    filterByBankAccounts,
    clearBankFilter,
    deleteTransaction,
    updateTransaction,
    BulkDelete,
    selectAllToggle,
    row_classes,
    getBankAccountDetails,
    //  Receives data from 'More Options Button' child
    ChangeEdit(bulkEdit) {
      this.bulkEdit = bulkEdit;
    },
    //  Close the bulk edit button
    CloseBulkEdit(bulkEdit, selected_transactions) {
      this.bulkEdit = bulkEdit;
      this.selected_transactions = selected_transactions;
    },
    //
    OpenUploadModal(dialog) {
      this.dialog = dialog;
    },
    parseFileCSV,
    CSVtoNewBankTransaction,
    //  Clears payment allocation modal
    BulkCreateJournalEntries() {
      this.bulk_journal_entries_dialog = !this.bulk_journal_entries_dialog;
    },
    closeBulkJournalEntriesDialog(bulk_journal_entries_dialog) {
      this.bulk_journal_entries_dialog = bulk_journal_entries_dialog;
    },
    unselectTransactions(selected_transactions) {
      this.selected_transactions = selected_transactions;
    },
    //  Sets the default search & date filters to the value of a route param
    //  Used when clicking through to view bank transactions from
    //  allocated payments or journal entries
    searchQuery() {
      return this.$route.query.search ? this.$route.query.search : "";
    },
    startDate() {
      return this.$route.query.start_date
        ? this.$route.query.start_date
        : `${new Date().toISOString().slice(0, 10).substring(0, 8)}01`;
    },
  },
};
</script>
<style scoped>
.truncate {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-width 0.3s;
}
.truncate_description {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-width 0.3s;
}
.truncate_description:hover {
  max-width: 300px;
  overflow-wrap: break-word;
}
.truncate:hover {
  max-width: 200px;
  overflow: visible;
}
</style>
