<template>
  <div>
    <v-container>
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Bank Accounts</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>

            <NewBankAccountButton
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('banking_write')"
            />
          </v-card-title>
        </v-col>
      </v-row>
      <!--Table skeleton loader-->
      <v-row v-if="firstLoad">
        <v-col>
          <v-sheet elevation="4">
            <v-skeleton-loader
              :loading="loading_user"
              type="table"
              max-height="500"
            >
            </v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <!--End of skeleton loader-->
      <v-row
        v-if="!firstLoad"
        v-show="this.user_roles_list.flat().includes('banking_read')"
      >
        <v-col>
          <v-sheet elevation="4">
            <v-tabs background-color="#6666cf" center-active dark>
              <v-tab>Bank Accounts</v-tab>
              <v-tab>Credit Cards</v-tab>
              <v-tab>Petty Cash</v-tab>
              <v-tab>Speed Points</v-tab>
              <!--Bank Accounts-->
              <v-tab-item>
                <v-card>
                  <v-card-title>
                    Bank Accounts<v-spacer></v-spacer>
                  </v-card-title>
                  <v-data-table
                    :headers="bankAccountsHeaders"
                    :items="bank_accounts"
                    :search="search"
                    :items-per-page="100"
                  >
                    <!--Edit Bank Account icon button-->
                    <template
                      v-slot:item.bank_account_id="{ item }"
                      v-if="
                        this.user_roles_list.flat().includes('banking_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-bank-account',
                          params: { bank_account_id: item.bank_account_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
              <!--Credit Cards-->
              <v-tab-item>
                <v-card>
                  <v-card-title>
                    Credit Cards<v-spacer></v-spacer>
                  </v-card-title>
                  <v-data-table
                    :headers="creditCardHeaders"
                    :items="credit_cards"
                    :search="search"
                    :items-per-page="100"
                  >
                  </v-data-table>
                </v-card>
              </v-tab-item>
              <!--Petty Cash-->
              <v-tab-item>
                <v-card>
                  <v-card-title>
                    Petty Cash Accounts<v-spacer></v-spacer>
                  </v-card-title>
                  <v-data-table
                    :headers="pettyCashHeaders"
                    :items="petty_cash"
                    :search="search"
                    :items-per-page="100"
                  >
                    <template
                      v-slot:item.bank_account_id="{ item }"
                      v-if="
                        this.user_roles_list.flat().includes('banking_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-petty-cash',
                          params: { bank_account_id: item.bank_account_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
              <!--Speed Point-->
              <v-tab-item>
                <v-card>
                  <v-card-title>
                    Speed Points<v-spacer></v-spacer>
                  </v-card-title>
                  <v-data-table
                    :headers="speedPointHeaders"
                    :items="speed_points"
                    :search="search"
                    :items-per-page="100"
                  >
                    <template
                      v-slot:item.bank_account_id="{ item }"
                      v-if="
                        this.user_roles_list.flat().includes('banking_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-speed-point',
                          params: { bank_account_id: item.bank_account_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import NewBankAccountButton from "./NewBankAccountButton";
import store from "../store/store";
import { mapState } from "vuex";
export default {
  name: "CategoriesTable",
  mixins: [mixin_UserRoles],
  components: { NewBankAccountButton },
  data() {
    return {
      pagination: {},
      search: "",
      bank_accounts: [],
      credit_cards: [],
      petty_cash: [],
      speed_points: [],
    };
  },
  // Gets bank accounts from firebase datatbase
  created() {
    const accountsArray = ["bank_accounts", "speed_points", "petty_cash"];
    accountsArray.forEach((doc) => {
      this.getMultipleFirestoreDocs(doc);
    });
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    bankAccountsHeaders: (state) => state.bankAccountsHeaders,
    creditCardHeaders: (state) => state.creditCardHeaders,
    pettyCashHeaders: (state) => state.pettyCashHeaders,
    speedPointHeaders: (state) => state.speedPointHeaders,
  }),
  methods: {
    getMultipleFirestoreDocs(collection) {
      db.collection(`${collection}`).onSnapshot((querySnapshot) => {
        this[collection] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this[collection].push(data);
        });
      });
    },
  },
};
</script>
