<template>
  <v-container fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('banking_write')">
      <v-col lg="8" offset-lg="2">
        <v-card-title>
          <v-btn to="/bank-statements" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Add New Inter Account Transfer</h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card>
          <!--New Machine form -->
          <v-form
            @submit.prevent="saveInterAccountTransfer"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h4>Step 1)</h4>
                  <p>Select the bank account to be debitted</p>
                </v-col>
              </v-row>
              <!--Cash Out-->
              <v-alert prominent text color="red">
                <v-row>
                  <!--Bank Account-->
                  <v-col cols="12" sm="12" md="6">
                    <h4 class="mb-3">
                      <v-icon class="red--text mdi-rotate-180" x-small left>
                        mdi-triangle</v-icon
                      >
                      From
                    </h4>
                    <v-autocomplete
                      v-model="bank_account_from"
                      :items="bank_accounts"
                      item-text="bank_account_name"
                      label="Bank Account"
                      outlined
                      dense
                      color="#3d2cdd"
                      :rules="bankRules"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <!--Transaction number-->
                  <v-col cols="12" sm="12" md="6">
                    <h4 class="mb-3">Transaction #</h4>
                    <v-text-field
                      readonly
                      v-model.number="transaction_number_from"
                      required
                      outlined
                      dense
                      color="#3d2cdd"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-alert>
              <!--Cash In-->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h4>Step 2)</h4>
                  <p>Select the bank account to be creditted</p>
                </v-col>
              </v-row>
              <v-alert prominent text color="green">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <h4 class="mb-3">
                      <v-icon class="green--text" x-small left>
                        mdi-triangle</v-icon
                      >To
                    </h4>
                    <v-autocomplete
                      v-model="bank_account_to"
                      :items="bank_accounts"
                      item-text="bank_account_name"
                      label="Bank Account"
                      outlined
                      dense
                      color="#3d2cdd"
                      :rules="bankRules"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <!--Transaction number-->
                  <v-col cols="12" sm="12" md="6">
                    <h4 class="mb-3">Transaction #</h4>
                    <v-text-field
                      readonly
                      v-model.number="transaction_number_to"
                      required
                      outlined
                      dense
                      color="#3d2cdd"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-alert>

              <!--Transaction amount-->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h4>Step 3)</h4>
                  <p>Confirm the amount & date of transfer</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.number="transaction_amount"
                    type="number"
                    label="Amount"
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="amountRules"
                    required
                  >
                  </v-text-field>
                </v-col>
                <!--Date Picker-->
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="transaction_date"
                        label="Transaction Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        color="#3d2cdd"
                        :rules="dateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="transaction_date"
                      no-title
                      scrollable
                      color="#3d2cdd"
                      show-adjacent-months
                      :allowed-dates="
                        (date) => date <= new Date().toISOString().substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#ce2458" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!--Hidden field that captures bank account id-->
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    readonly
                    v-model="bank_account_from_id"
                    :value="getFromBankAccountDetails()"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    style="visibility: hidden"
                  >
                  </v-text-field>
                  <v-text-field
                    readonly
                    v-model="bank_account_to_id"
                    :value="getToBankAccountDetails()"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    style="visibility: hidden"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/bank-statements" color="#ce2458" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="saveInterAccountTransfer"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Submit
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
  </v-container>
</template>

<script>
//Connect to database
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { showSnackbar } from "../../../globalActions/index";
import { newDateToISO, firebaseTimestamp } from "../../../composables/external";
import {
  getBankAccounts,
  captureUser,
} from "../data/external_bank_transactions";

export default {
  name: "NewInterAccountTransfer",
  mixins: [mixin_UserRoles],
  components: { NoPermissionsCard },

  data() {
    return {
      valid: false,
      loading: false,
      bank_account_from: null,
      bank_account_to: null,
      bank_account_from_id: null,
      bank_account_to_id: null,
      bank_accounts: [],
      transaction_input_source: "Manual",
      transaction_number_from: null,
      transaction_number_to: null,
      transaction_allocation_status: "Inter Account Transfer",
      transaction_date: null,
      transaction_amount: 0,
      transaction_created_by_id: null,
      transaction_created_by_name: null,
      attachments: [],
      menu: null,
      date: null,
      bankRules: [(v) => !!v || "You must select a bank account"],
      dateRules: [(v) => !!v || "You must select a date"],
      amountRules: [
        (v) => !!v || "You must enter an amount",
        (v) => v <= 10000000000 || "The amount is too long",
        //  Need to limit input to two floating points
        //  Need to remove 'e' input
      ],
    };
  },
  created() {
    this.getBankAccounts();
    this.captureUser();
    this.formatFromTransactionNumber();
    this.formatToTransactionNumber();
  },
  conputed: {},
  methods: {
    getBankAccounts,
    captureUser,
    validate() {
      this.$refs.form.validate();
    },
    //Reroute to datatable
    routeToTable() {
      this.loading = false;
      showSnackbar("New inter account transfer added successfully");
      this.$router.push("/bank-statements");
    },
    formatFromTransactionNumber() {
      db.collection("bank_transaction_counters")
        .doc("f4FkXIYnlf0ZhdmJUfcG")
        .onSnapshot((snapshot) => {
          // Gets order and quote counter object from datatbase
          const debit_count = snapshot.data().debit_count;
          this.transaction_number_from =
            debit_count < 1000
              ? `DB-${String("00000" + debit_count).slice(-4)}`
              : `DB-${debit_count}`;
        });
    },
    formatToTransactionNumber() {
      db.collection("bank_transaction_counters")
        .doc("f4FkXIYnlf0ZhdmJUfcG")
        .onSnapshot((snapshot) => {
          // Gets order and quote counter object from datatbase
          const credit_count = snapshot.data().credit_count;
          this.transaction_number_to =
            credit_count < 1000
              ? `CD-${String("00000" + credit_count).slice(-4)}`
              : `CD-${credit_count}`;
        });
    },
    increaseTransactionCounter() {
      const increment = firebase.firestore.FieldValue.increment(1);
      const increaseCount = firebase
        .firestore()
        .collection("bank_transaction_counters")
        .doc("f4FkXIYnlf0ZhdmJUfcG");
      increaseCount.update({ credit_count: increment });
      increaseCount.update({ debit_count: increment });
    },
    makeid() {
      let text = "";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 21; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    saveInterAccountTransfer() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const batch = db.batch();

        const debit_id = this.makeid();
        const credit_id = this.makeid();
        var newDebitRef = db.collection("bank_transactions").doc(debit_id);

        batch.set(newDebitRef, {
          transaction_id: debit_id,
          transaction_number: this.transaction_number_from,
          transaction_type: "Cash Out",
          transaction_date: this.transaction_date,
          transaction_amount: this.transaction_amount,
          transaction_allocation_status: "Inter Account Transfer",
          bank_account_name: this.bank_account_from,
          bank_account_id: this.bank_account_from_id,
          transaction_input_source: "Manual",
          transaction_created_by_id: this.transaction_created_by_id,
          transaction_created_by_name: this.transaction_created_by_name,
          transaction_date_created: newDateToISO(),
          transaction_timestamp: firebaseTimestamp(),
          related_transaction_id: credit_id,
          related_transaction_number: this.transaction_number_to,
          related_transaction_bank: this.bank_account_to,
          attachments: [],
        });
        var newCreditRef = db.collection("bank_transactions").doc(credit_id);
        batch.set(newCreditRef, {
          transaction_id: credit_id,
          transaction_number: this.transaction_number_to,
          transaction_type: "Cash In",
          transaction_date: this.transaction_date,
          transaction_amount: this.transaction_amount,
          transaction_allocation_status: "Inter Account Transfer",
          bank_account_name: this.bank_account_to,
          bank_account_id: this.bank_account_to_id,
          transaction_input_source: "Manual",
          transaction_created_by_id: this.transaction_created_by_id,
          transaction_created_by_name: this.transaction_created_by_name,
          transaction_date_created: newDateToISO(),
          transaction_timestamp: firebaseTimestamp(),
          related_transaction_id: debit_id,
          related_transaction_number: this.transaction_number_from,
          related_transaction_bank: this.bank_account_from,
          attachments: [],
        });

        batch
          .commit()
          .then(() => {
            this.increaseTransactionCounter();
            this.routeToTable();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getToBankAccountDetails() {
      const accountsArray = ["bank_accounts", "speed_points"];
      accountsArray.forEach((item) => {
        db.collection(`${item}`)
          .where("bank_account_status", "==", "Active")
          .where("bank_account_name", "==", this.bank_account_to)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              this.bank_account_to_id = data.bank_account_id;
            });
          });
      });
    },
    getFromBankAccountDetails() {
      const accountsArray = ["bank_accounts", "speed_points"];
      accountsArray.forEach((item) => {
        db.collection(`${item}`)
          .where("bank_account_status", "==", "Active")
          .where("bank_account_name", "==", this.bank_account_from)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              this.bank_account_from_id = data.bank_account_id;
            });
          });
      });
    },
  },
};
</script>
