<template>
  <v-container fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('banking_write')">
      <v-col lg="6" offset-lg="3">
        <v-card-title>
          <v-btn to="/bank-statements" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Edit Bank Transaction</h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card>
          <!--New Machine form -->
          <v-form
            @submit.prevent="updateBankTransaction"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <!--Bank Account-->
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="bank_account_name"
                    :items="bank_accounts"
                    item-text="bank_account_name"
                    label="Bank Account"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-autocomplete>
                </v-col>
                <!--Transaction number-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    readonly
                    v-model.number="transaction_number"
                    label="Transaction #"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Transaction Date-->
              <v-row>
                <!--Amount-->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.number="transaction_amount"
                    min="0"
                    step="0.01"
                    type="number"
                    label="Amount"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <!--Date Picker-->
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="transaction_date"
                        label="Transaction Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                        color="#3d2cdd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="transaction_date"
                      no-title
                      scrollable
                      color="#3d2cdd"
                      show-adjacent-months
                      :allowed-dates="
                        (date) => date <= new Date().toISOString().substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#ce2458" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <!--Description-->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="transaction_description"
                    label="Description"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Hidden field that captures bank account id-->
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    readonly
                    v-model="bank_account_id"
                    :value="getBankAccountDetails()"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    style="visibility: hidden"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/bank-statements" color="#ce2458" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="updateBankTransaction"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Submit
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
  </v-container>
</template>

<script>
//Connect to database
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { showSnackbar } from "../../../globalActions/index";

export default {
  name: "NewMachine",
  mixins: [mixin_UserRoles],
  components: { NoPermissionsCard },

  data() {
    return {
      valid: null,
      transaction_id: null,
      transaction_type: null,
      loading: null,
      bank_accounts: [],
      bank_account_name: null,
      bank_account_id: null,
      transaction_number: null,
      transaction_allocation_status: "No",
      transaction_date: null,
      transaction_amount: null,
      transaction_description: null,
      transaction_created_by_id: null,
      transaction_created_by_name: null,
      attachments: null,
      menu: null,
      date: null,
    };
  },
  // Gets bank accounts from database
  created() {
    db
      .collection("bank_accounts")
      .where("bank_account_status", "==", "Active")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          //Pushes the customers name to the empty customers array in 'data' above
          this.bank_accounts.push(data);
        });
      }),
      //Captures user that created the transaction order
      (this.transaction_created_by_id = firebase.auth().currentUser.uid);
    db
      .collection("users")
      .where("uid", "==", this.transaction_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.transaction_created_by_name = doc.data().displayName;
        });
      }),
      //Get credit and debit counters from database

      db
        .collection("bank_transaction_counters")
        .doc("f4FkXIYnlf0ZhdmJUfcG")
        .onSnapshot((snapshot) => {
          // Gets corder and quote counter object from datatbase
          const debit_count = snapshot.data().debit_count;
          const credit_count = snapshot.data().credit_count;
          //Is debit
          if (this.$route.params.transaction_type === "Cash Out") {
            //Format debit number
            if (debit_count < 10) {
              this.transaction_number = `DB-000${debit_count}`;
            } else if (debit_count >= 10 && debit_count < 100) {
              this.transaction_number = `DB-00${debit_count}`;
            } else if (debit_count >= 100 && debit_count < 1000) {
              this.transaction_number = `DB-0${debit_count}`;
            } else {
              this.transaction_number = `DB-${debit_count}`;
            }
          }
          //Is credit
          else {
            //Format credit number
            if (credit_count < 10) {
              this.transaction_number = `CD-000${credit_count}`;
            } else if (credit_count >= 10 && credit_count < 100) {
              this.transaction_number = `CD-00${credit_count}`;
            } else if (credit_count >= 100 && credit_count < 1000) {
              this.transaction_number = `CD-0${credit_count}`;
            } else {
              this.transaction_number = `CD-${credit_count}`;
            }
          }
        });
  },
  conputed: {
    transaction_type() {
      return this.$route.params.transaction_type;
    },
  },
  methods: {
    //Reroute to datatable
    routeToTable() {
      this.loading = false;
      showSnackbar("New transaction added successfully");
      this.$router.push("/bank-statements");
    },
    //Increment transaction number counter
    increaseCounter() {
      const db = firebase.firestore();
      const increment = firebase.firestore.FieldValue.increment(1);
      const increaseCount = db
        .collection("bank_transaction_counters")
        .doc("f4FkXIYnlf0ZhdmJUfcG");
      //Debit or credit
      if (this.$route.params.transaction_type === "Cash In") {
        increaseCount.update({ credit_count: increment });
      } else {
        increaseCount.update({ debit_count: increment });
      }
    },
    updateBankTransaction() {
      //Form validation --> ensures required fields aren't blank before continuing further
      if (this.$refs.form.validate()) {
        this.loading = true;
        //Sets the category_id == to the document id in database
        var newDocRef = db.collection("bank_transactions").doc();
        newDocRef
          .set({
            //Populated by the v-model values in the form
            transaction_id: newDocRef.id,
            transaction_number: this.transaction_number,
            transaction_type: this.$route.params.transaction_type,
            transaction_date: this.transaction_date,
            transaction_amount: this.transaction_amount,
            transaction_allocation_status: this.transaction_allocation_status,
            bank_account_name: this.bank_account_name,
            bank_account_id: this.bank_account_id,
            transaction_created_by_id: this.transaction_created_by_id,
            transaction_created_by_name: this.transaction_created_by_name,
            transaction_date_created: new Date().toISOString().slice(0, 10),
            transaction_timestamp:
              firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            //Increment counter
            this.increaseCounter();

            //Back to bank statement
            this.routeToTable();
          });
      }
    },
    getBankAccountDetails() {
      db.collection("bank_accounts")
        .where("bank_account_name", "==", this.bank_account_name)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.bank_account_id = data.bank_account_id;
          });
        });
    },
  },
};
</script>
