<template>
  <v-container fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('banking_write')">
      <v-col lg="6" offset-lg="3">
        <FormHeader v-bind="editBankAccountHeader" />
        <v-card>
          <!--New Machine form -->
          <v-form
            @submit.prevent="updateBankAccount"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selectedBank"
                    @change="getBankInfo()"
                    :items="bank_list"
                    item-text="name"
                    return-object
                    counter="50"
                    label="Select Bank"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="bankRules"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model.number="bank_account_number"
                    type="number"
                    counter="20"
                    label="Account Number"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="accountNumberRules"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model.number="bank_account_branch_code"
                    type="number"
                    counter="50"
                    label="Branch Code"
                    readonly
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="bank_account_swift_code"
                    counter="50"
                    label="Swift Code"
                    readonly
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="bank_account_type"
                    label="Account Type"
                    :items="[
                      'Cheque Account',
                      'Savings Account',
                      'Money Market Account',
                      'Fixed / Deposit Account',
                    ]"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                    :rules="accountTypeRules"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="bank_account_status"
                    label="Status"
                    :items="['Active', 'Inactive']"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!--Opening Balance-->
              <v-row class="mt-3">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model.number="bank_account_opening_balance"
                    type="number"
                    label="Opening Balance"
                    required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/bank-accounts" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="confirmDeleteBankAccount"
              color="#ce2458"
              text
              x-large
            >
              Delete
              <v-icon right>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              @click="updateBankAccount"
              color="#33cc99"
              text
              x-large
              :loading="loading"
              :disabled="!valid"
            >
              Update
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
    <!--Delete Alert Modal-->
    <BankAccountsDeleteDisableDialog :delete_alert_modal="delete_alert_modal" />
    <!--Delete Confirmation Modal-->
    <BankAccountDeleteConfirmDialog
      :delete_bank_account_modal="delete_bank_account_modal"
      :loading_delete="loading_delete"
      @deleteAccount="deleteBankAccount($event)"
      @closeDialog="closeDialog($event)"
    />
  </v-container>
</template>

<script>
//Connect to database
import db from "../../../components/firebaseInit";
import FormHeader from "../../../components/Forms/FormHeader";
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import store from "../store/store";
import { mapState } from "vuex";
import { showSnackbar } from "../../../globalActions/index";
import mixin_bank_account_validation_rules from "../mixins/mixin_bank_account_validation_rules";
import {
  deleteBankAccount,
  testTransactionLength,
  confirmDeleteBankAccount,
  updateBankAccount,
} from "../data/external_bank_accounts";
import { firestoreDocProps } from "../../../composables/external";

export default {
  name: "NewMachine",
  mixins: [mixin_UserRoles, mixin_bank_account_validation_rules],
  components: {
    FormHeader,
    NoPermissionsCard,
    BankAccountsDeleteDisableDialog: () =>
      import("../components/BankAccountsDeleteDisableDialog"),
    BankAccountDeleteConfirmDialog: () =>
      import("../components/BankAccountDeleteConfirmDialog"),
  },

  data() {
    return {
      //Initial form values
      bank_account_id: null,
      bank_account_name: null,
      bank_account_number: null,
      bank_account_branch_code: null,
      bank_account_swift_code: null,
      bank_account_type: null,
      bank_account_status: null,
      primary_bank_account: null,
      bank_account_opening_balance: null,
      bank_account_created_by_id: null,
      bank_account_created_by_name: null,
      bank_account_date_added: null,
      bank_account_timestamp: null,
      account_id: null,
      loading: null,
      loading_delete: false,
      selectedBank: null,
      transactions: [],
      delete_alert_modal: false,
      delete_bank_account_modal: false,
      bank_interface_type: "bank_accounts",
      propArray: [
        "bank_account_id",
        "bank_account_name",
        "bank_account_number",
        "bank_account_branch_code",
        "bank_account_swift_code",
        "bank_account_type",
        "bank_account_status",
        "primary_bank_account",
        "bank_account_opening_balance",
        "account_id",
      ],

      //List of banks
      bank_list: [
        {
          name: "Absa Bank Limited",
          branch: "632005",
          swift: "ABSAZAJJXXX",
        },
        {
          name: "African Bank Limited",
          branch: "430000",
          swift: "AFRCZAJJXXX",
        },
        {
          name: "Bidvest Bank Limited",
          branch: "462005",
          swift: "BIDBZAJJXXX",
        },
        {
          name: "Capitec Bank Limited",
          branch: "470010",
          swift: "CABLZAJJXXX",
        },
        { name: "Discovery Bank Limited", branch: "679000", swift: "DISCZAJJ" },
        { name: "First National Bank", branch: "250655", swift: "FIRNZAJJ" },
        { name: "FirstRand Bank", branch: "250655", swift: "FIRNZAJJRSL" },
        {
          name: "Grindrod Bank Limited",
          branch: "223626",
          swift: "GRIDZAJJXXX",
        },
        {
          name: "Investec Bank Limited",
          branch: "580105",
          swift: "IVESZAJJXXX",
        },
        {
          name: "Merchantile Bank Limited",
          branch: "450105",
          swift: "LISAZAJJXXX",
        },
        { name: "Nedbank Limited", branch: "198765", swift: "NEDSZAJJXXX" },
        { name: "Old Mutual", branch: "462005", swift: "OMAMZAJCXXX" },
        { name: "Sasfin Bank Limited", branch: "683000", swift: "SASFZAJJXXX" },
        { name: "Standard Bank", branch: "051001", swift: "SBZAZAJJ" },
        { name: "Tyme Bank", branch: "678910", swift: "CBZAZAJJ" },
      ],
    };
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    editBankAccountHeader: (state) => state.editBankAccountHeader,
  }),
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("bank_accounts")
      .where("bank_account_status", "==", "Active")
      .where("bank_account_id", "==", to.params.bank_account_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.selectedBank = doc.data().bank_account_name;
            vm.bank_account_id = doc.data().bank_account_id;
            vm.bank_account_name = doc.data().bank_account_name;
            vm.bank_account_number = doc.data().bank_account_number;
            vm.bank_account_branch_code = doc.data().bank_account_branch_code;
            vm.bank_account_swift_code = doc.data().bank_account_swift_code;
            vm.bank_account_type = doc.data().bank_account_type;
            vm.bank_account_status = doc.data().bank_account_status;
            vm.primary_bank_account = doc.data().primary_bank_account;
            vm.bank_account_opening_balance =
              doc.data().bank_account_opening_balance;
            vm.account_id = doc.data().account_id;
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  created() {
    //  Test if there are any transactions related to this account
    this.testTransactionLength();
  },
  methods: {
    deleteBankAccount,
    testTransactionLength,
    confirmDeleteBankAccount,
    updateBankAccount,
    firestoreDocProps,
    fetchData() {
      db.collection("bank_accounts")
        .doc(this.$route.params.bank_account_id)
        .get()
        .then((doc) => {
          this.propArray.forEach((prop) => {
            return (this[prop] = doc.data[prop]);
          }),
            (this.selectedBank = doc.data().bank_account_name);
        });
    },
    //Reroute to bank account page after database has been updated
    routeToTable(message) {
      this.loading_update = false;
      showSnackbar(`Bank account ${message} successfully`);
      this.$router.push("/bank-accounts");
    },
    validate() {
      this.$refs.form.validate();
    },
    getBankInfo() {
      if (this.selectedBank !== null) {
        this.bank_account_name = this.selectedBank.name;
        this.bank_account_branch_code = this.selectedBank.branch;
        this.bank_account_swift_code = this.selectedBank.swift;
      }
    },
    closeDialog(delete_bank_account_modal) {
      this.delete_bank_account_modal = delete_bank_account_modal;
    },
  },
};
</script>
