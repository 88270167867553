const mixin_tags = {
    data() {
        return {
            tags: [
                {
                    name: "Missing Attachment",
                    icon: "mdi-file-alert-outline",
                    toggled: false,
                },
                {
                    name: "Incorrect Date",
                    icon: "mdi-calendar-question",
                    toggled: false,
                },
                { name: "Incorrect Amount", icon: "mdi-cash-remove", toggled: false },
                {
                    name: "Incorrect Bank Account",
                    icon: "mdi-bank-remove",
                    toggled: false,
                },
                {
                    name: "Allocated Incorrecty",
                    icon: "mdi-calculator-variant",
                    toggled: false,
                },
                {
                    name: "Outstanding Card Authorisation",
                    icon: "mdi-history",
                    toggled: false,
                },
            ],
        }
    }
}

export default mixin_tags