<template>
  <div>
    <Form
      ref="form"
      :form="form"
      :valid="valid"
      :loading="loading"
      :isNew="Object.keys($route.params).length === 0 ? true : false"
      @passChange="updateInput($event)"
      @passValid="updateValid($event)"
      @passRefresh="refreshInput($event)"
    />
    <!--Delete Alert Modal-->
    <BankAccountsDeleteDisableDialog :delete_alert_modal="delete_alert_modal" />
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import {
  addBankAccount,
  updateAccount,
  deleteAccount,
  captureUser,
  testTransactionLength,
} from "../data/external_bank_accounts";
import {
  initFormData,
  getFormData,
  assignFormData,
  updateInput,
  refreshInput,
  updateValid,
  fieldRules,
} from "../../../components/Forms/formInit";
//
const formDataArray = [
  "account_id",
  "bank_account_id",
  "bank_account_name",
  "bank_account_opening_balance",
  "bank_account_status",
  "bank_account_type",
  "bank_account_created_by_id",
  "bank_account_created_by_name",
  "bank_account_date_added",
  "bank_account_timestamp",
];
const formDataObj = initFormData(formDataArray);
export default {
  name: "PettyCashForm",
  components: {
    Form: () => import("../../../components/Forms/Form.vue"),
    BankAccountsDeleteDisableDialog: () =>
      import("../components/BankAccountsDeleteDisableDialog"),
  },
  data() {
    return {
      ...formDataObj,
      valid: false,
      loading: false,
      bank_account_status: "Active",
      bank_account_type: "Petty Cash",
      transactions: [],
      delete_alert_modal: false,
      ...fieldRules,
    };
  },
  // "Form" must be declared as a computed function and not in the "data" return object
  // This is because we need access to the "this" context of the initial data properties
  computed: {
    form: function () {
      return {
        header: `${
          Object.keys(this.$route.params).length === 0 ? "Add New" : "Edit"
        } Petty Cash Account`,
        fields: [
          {
            id: 1,
            title: "Petty Cash Info",
            inputs: [
              {
                name: "Name",
                value: this.bank_account_name,
                data: "bank_account_name",
                type: "text",
                rules: {
                  required: this.fieldRules.required,
                  maxLength: this.fieldRules.maxLength,
                },
                required: true,
              },
              {
                name: "Status",
                value: this.bank_account_status,
                data: "bank_account_status",
                type: "select",
                items: ["Active", "Inactive"],
              },
            ],
          },
          {
            id: 2,
            title: "Opening Balance",
            inputs: [
              {
                name: "Opening Balance",
                value: this.bank_account_opening_balance,
                data: "bank_account_opening_balance",
                type: "text",
                required: true,
                rules: {
                  required: this.fieldRules.required,
                },
              },
            ],
          },
        ],
        action: {
          add: () => this.addBankAccount("petty_cash", "Petty Cash"),
          edit: () => this.updateAccount("petty_cash"),
          delete: () => this.deleteAccount("petty_cash"),
        },
      };
    },
  },
  //

  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    if (Object.keys(to.params).length === 0) next();
    const dbQuery = db
      .collection("petty_cash")
      .where("bank_account_id", "==", to.params.bank_account_id);
    //
    getFormData(formDataArray, dbQuery, to, next);
  },
  created() {
    this.captureUser();
    this.testTransactionLength();
    const dbQuery = db
      .collection("petty_cash")
      .where("bank_account_id", "==", this.$route.params.bank_account_id);
    if (Object.keys(this.$route.params).length !== 0)
      this.assignFormData(formDataArray, dbQuery);
  },
  methods: {
    updateInput,
    updateValid,
    refreshInput,
    addBankAccount,
    updateAccount,
    deleteAccount,
    testTransactionLength,
    captureUser,
    getFormData,
    assignFormData,
  },
};
</script>