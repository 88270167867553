import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import { newDateToISO, firebaseTimestamp, firestoreDocProps } from '../../../composables/external'

//  Format transaction number
export const formatTransactionNumber = function () {
    db.collection('bank_transaction_counters').doc('f4FkXIYnlf0ZhdmJUfcG')
        .onSnapshot(snapshot => {
            // Gets order and quote counter object from datatbase
            const debit_count = snapshot.data().debit_count
            const credit_count = snapshot.data().credit_count
            const evalTransaction = (count, prefix) => {
                this.transaction_number = count < 1000 ?
                    `${prefix}-${String("00000" + count).slice(-4)}` : `${prefix}-${count}`
            }
            this.$route.params.transaction_type === 'Cash Out' ?
                evalTransaction(debit_count, 'DB') :
                evalTransaction(credit_count, 'CD')
        })
}
//  Get bank accounts from db
export const getBankAccounts = function () {
    const accountsArray = ["bank_accounts", "speed_points", "petty_cash"];
    accountsArray.forEach((item) => {
        db.collection(`${item}`)
            .where("bank_account_status", "==", "Active")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    this.bank_accounts.push(data);
                });
            });
    });
}
//  Get details related to selected bank account
export const getThisBankAccountDetails = function () {
    const accountsArray = ["bank_accounts", "speed_points", "petty_cash"];
    accountsArray.forEach((item) => {
        db.collection(`${item}`).where('bank_account_name', '==', this.bank_account_name)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    const data = doc.data()
                    this.bank_account_id = data.bank_account_id
                })
            })
    })
}

//  Capture current user
export const captureUser = function () {
    this.transaction_created_by_id = firebase.auth().currentUser.uid
    db.collection('users').where('uid', '==', this.transaction_created_by_id).get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
            this.transaction_created_by_name = doc.data().displayName
        })
    })
}
//___________________________________________________________
//  Save bank transaction to database
export const saveBankTransaction = function () {
    const promises = [];
    const date = this.transaction_date.slice(0, -3)
    const transactionAmount = this.transaction_amount.toFixed(4);
    const transactionType = this.$route.params.transaction_type;
    if (this.$refs.form.validate()) {
        this.loading = true
        //  ACCOUNTING LOGIC
        // 1.  Evaluate transaction type and add amount to their
        //     respective "Suspense" ledger
        const addAsSuspenseEntry = () => {
            const docID = (transactionType === "Cash In") ?
                "RSAgQsASdXZKhQRwMZUz" : "44UTKB7Og5aZLcOvaSOl";
            promises.push(
                db.doc(`accounting_totals/${date}/ledger_accounts/${docID}`)
                    .update({
                        monthly_total: firebase.firestore.FieldValue
                            .increment(Math.abs(transactionAmount)),
                    })
                    .then(() => console.log("1. Suspense account updated"))
                    .catch((error) => error)
            );
        }
        // END OF ACCOUNTING LOGIC
        // 2.   Add bank transaction to database
        const addBankTransactionToDB = () => {
            const newDocRef = db.collection('bank_transactions').doc()
            promises.push(
                newDocRef.set({
                    ...firestoreDocProps([
                        "transaction_number",
                        "transaction_date",
                        "transaction_amount",
                        "transaction_description",
                        "transaction_allocation_status",
                        "bank_account_name",
                        "bank_account_id",
                        "transaction_created_by_id",
                        "transaction_created_by_name"
                    ], this),
                    transaction_id: newDocRef.id,
                    transaction_type: this.$route.params.transaction_type,
                    transaction_input_source: 'Manual',
                    transaction_date_created: newDateToISO(),
                    transaction_timestamp: firebaseTimestamp(),
                    attachments: [],
                })
                    .then(() => console.log("2. Bank transaction added to database"))
                    .catch(err => console.log(err))
            );
        }
        // 3.   Increment transaction number counters
        const increaseTransactionCounter = () => {
            const increment = firebase.firestore.FieldValue.increment(1);
            const increaseCount = firebase.firestore().collection('bank_transaction_counters').doc('f4FkXIYnlf0ZhdmJUfcG');
            //Debit or credit
            this.$route.params.transaction_type === 'Cash In' ?
                promises.push(increaseCount.update({ credit_count: increment })
                    .then(() => this.routeToTable())
                    .catch((error) => error)
                )
                :
                promises.push(increaseCount.update({ debit_count: increment })
                    .then(() => this.routeToTable())
                    .catch((error) => error)
                )
        }
        addAsSuspenseEntry()
        addBankTransactionToDB()
        increaseTransactionCounter()
        Promise.all(promises)
    }
}